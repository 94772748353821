<template>
  <div class="flag__items">
    <button
      v-for="entry in languages"
      :key="entry.title"
      @click="changeLocale(entry.language)"
      class="btn--lang"
    >
      <flag :iso="entry.flag" v-bind:squared="false" />
      {{ entry.title.slice(0, 2).toUpperCase() }}
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fullName: "",
      isActive: true,
      languages: [
        { flag: "us", language: "en", title: "English" },
        { flag: "al", language: "al", title: "Albanian" },
      ],
    };
  },
  methods: {
    changeLocale(locale) {
      this.$i18n.locale = locale;
      localStorage.setItem("lang", locale);
    },
  },
};
</script>
