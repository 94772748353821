<template>
  <div class="modal" v-click-outside="onClickOutside">
    <div class="modal__wrapper">
      <h2 class="modal__title">{{ $t("edit_subject") }}</h2>
      <form @submit.prevent="update" class="modal__form">
        <div class="modal__form__input">
          <app-input
            :hasErrors="!!errors.generalMsg"
            :class="{ hasError: errors.generalMsg || errors.className }"
            class="modal__form__input--type"
            type="text"
            name="className"
            :label="$t('edit_subject')"
            v-model="className"
          />
          <p class="error">{{ errors.className }}</p>
          <p class="error">{{ errors.generalMsg }}</p>
        </div>
        <div class="modal__btns">
          <app-button
            @click.native="
              $store.commit('modals/SET_SHOW_VIEW_CLASS_MODAL', false)
            "
            class="btn btn--cancel"
            type="button"
          >
            {{ $t("cancel") }}
          </app-button>
          <app-button class="btn btn--save" type="submit">
            {{ $t("save") }}
          </app-button>
        </div>
      </form>
      <confirm-dialogue ref="ConfirmDialogue" />
      <a
        class="modal__close"
        @click="$store.commit('modals/SET_SHOW_VIEW_CLASS_MODAL', false)"
        ><img src="../../assets/icons/close.svg"
      /></a>
    </div>
  </div>
</template>

<script>
import AppInput from "@/components/global/AppInput.vue";
import AppButton from "@/components/global/AppButton.vue";
import ConfirmDialogue from "../global/ConfirmDialogue.vue";
import { mapActions } from "vuex";
import api from "@/api/api";
import vClickOutside from "v-click-outside";

export default {
  components: { AppButton, AppInput, ConfirmDialogue },
  name: "showActivityModal",
  data() {
    return {
      idClass: 0,
      show: true,
      className: "",
      value: "",
      errors: {
        className: "",
        generalMsg: "",
      },
    };
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    classItem: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    const { id, subject } = this.classItem;
    this.idClass = id;
    this.className = subject;
  },
  watch: {
    className() {
      if (this.className) {
        this.errors.className = "";
      }
    },
    generalMsg() {
      if (!this.className) {
        this.errors.generalMsg = "";
        this.errors.className = "";
      }
    },
  },
  methods: {
    ...mapActions({
      classesList: "classesList/classesList",
      updateClass: "classesList/updateClass",
      getClasses: "classesList/getClasses",
    }),
    async update() {
      this.errors.className = !this.className ? this.$t("empty_field") : "";
      this.errors.generalMsg = "";

      if (this.className) {
        let dataToSend = {
          name: this.className,
          id: this.idClass,
        };
        if (this.className === this.classItem.subject) {
          this.errors.generalMsg = this.$t("subject_exists");
          return;
        }
        try {
          await api({ requiresAuth: true }).put(
            `/subject/update/${dataToSend.id}`,
            {
              name: this.className,
            }
          );
          this.updateClass(dataToSend);
          this.errors.generalMsg = "";
          let nextId = 1;
          const notification = {
            type: "success",
            message: this.$t("success_update_subject"),
            id: nextId++,
          };
          this.$store.commit("notification/ADD_NOTIFICATION", notification, {
            root: true,
          });
          this.$store.commit("modals/SET_SHOW_VIEW_CLASS_MODAL", false);
        } catch (err) {
          if (err.response.status === 400) {
            this.errors.generalMsg = this.$t("subject_exists");
          }
        }
      }
    },
    onClickOutside() {
      this.$store.commit("modals/SET_SHOW_VIEW_CLASS_MODAL", false);
    },
  },
};
</script>
<style lang="scss">
@import "../../assets/scss/components/global/modal-global";
</style>
