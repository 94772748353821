<template>
  <div class="modal modal--filter" v-click-outside="onClickOutside">
    <div class="modal__wrapper">
      <h2 class="modal__title">FILTER</h2>
      <form @submit.prevent="filterAllStats" class="modal__form">
        <div class="modal__form__input">
          <app-input
            :hasErrors="true"
            :label="$t('age')"
            v-model="age"
            name="age"
            type="number"
            class="modal__form__input--type"
          />
          <div class="modal__form__input--range">
            <app-input
              :label="$t('from')"
              v-model="fromAge"
              name="fromAge"
              type="number"
              class="modal__form__input--type"
              :hasErrors="true"
            />
            <app-input
              :label="$t('to')"
              v-model="toAge"
              :hasErrors="true"
              name="toAge"
              type="number"
              class="modal__form__input--type"
            />
          </div>
          <app-input
            id="grade"
            :hasErrors="true"
            :label="$t('class')"
            v-model="grade"
            name="grade"
            type="number"
            class="modal__form__input--type"
          />
          <div class="modal__form__input--range">
            <app-input
              :hasErrors="true"
              :label="$t('from')"
              v-model="fromClass"
              name="fromClass"
              type="number"
              class="modal__form__input--type"
            />
            <app-input
              :hasErrors="true"
              :label="$t('to')"
              v-model="toClass"
              name="toClass"
              type="number"
              class="modal__form__input--type"
            />
          </div>
          <div class="modal__gender">
            <label for="male">
              <input
                type="radio"
                id="male"
                ref="male"
                value="male"
                v-model="gender"
              />
              <span class="modal__gender--title">{{ $t("male_select") }}</span>
            </label>
            <label for="female">
              <input
                type="radio"
                id="female"
                ref="female"
                value="female"
                v-model="gender"
              />
              <span class="modal__gender--title">{{
                $t("female_select")
              }}</span>
            </label>
            <label for="uncheck">
              <input
                type="radio"
                id="uncheck"
                ref="uncheck"
                value="uncheck"
                v-model="uncheck"
                @click="deSelect()"
              />
              <span class="modal__gender--title">{{ $t("all") }}</span>
            </label>
          </div>
        </div>
        <div class="modal__btns">
          <app-button
            class="btn btn--cancel"
            type="button"
            @click.native="$store.commit('modals/SET_SHOW_FILTER_POPUP', false)"
            >{{ $t("cancel") }}
          </app-button>
          <app-button
            class="btn btn--save"
            @click.native="filterAllStats"
            type="submit"
            >{{ $t("save") }}</app-button
          >
        </div>
        <transition name="fade">
          <div class="page__modal page__modal__view" v-if="showChartPopup">
            <children-stats-popup />
          </div>
        </transition>
      </form>
    </div>
  </div>
</template>

<script>
import AppInput from "../global/AppInput.vue";
import AppButton from "../global/AppButton.vue";
import vClickOutside from "v-click-outside";
import { mapGetters, mapActions } from "vuex";
import ChildrenStatsPopup from "@/components/Modal/ChildrenStatsPopup.vue";

export default {
  name: "FilterPopUp",
  components: { AppInput, AppButton, ChildrenStatsPopup },
  data() {
    return {
      age: "",
      fromAge: null,
      toAge: null,
      grade: "",
      fromClass: null,
      toClass: null,
      page: 1,
      perPage: 10,
      gender: "",
      allChildren: true,
      uncheck: "uncheck",
      errors: {
        age: "",
        fromAge: "",
        toAge: "",
        grade: "",
        fromClass: "",
        toClass: "",
        gender: "",
      },
    };
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  watch: {
    gender() {
      if (this.gender === "female") {
        this.uncheck = "";
      }
      if (this.gender === "male") {
        this.uncheck = "";
      }
    },
  },

  computed: {
    ...mapGetters({
      children: "children/children",
      showChartPopup: "modals/showChartPopup",
    }),
  },

  methods: {
    deSelect() {
      this.gender = "";
    },
    ...mapActions({
      childrenStats: "children/childrenStats",
    }),
    onClickOutside() {
      this.$store.commit("modals/SET_SHOW_FILTER_POPUP", false);
    },
    async fetchChildren() {
      await this.childrenStats({
        age: this.$route.query.age || this?.age,
        age_from: this.$route.query.age_from || this.fromAge,
        age_to: this.$route.query.age_to || this.toAge,
        class: this.$route.query.class || this.grade,
        class_from: this.$route.query.class_from || this.fromClass,
        class_to: this.$route.query.class_to || this.toClass,
        gender: this.$route.query.gender || this.gender,
      });
    },
    async filterAllStats() {
      let dataToSend = {
        age: this.age,
        age_from: this.fromAge,
        age_to: this.toAge,
        class: this.grade,
        class_from: this.fromClass,
        class_to: this.toClass,
        gender: this.gender,
      };
      for (const key in dataToSend) {
        if (Object.hasOwnProperty.call(dataToSend, key)) {
          const element = dataToSend[key];
          if (element === null || element === "") {
            delete dataToSend[key];
          }
        }
      }
      if (
        this.age ||
        this.fromAge ||
        this.toAge ||
        this.grade ||
        this.fromClass ||
        this.toClass ||
        this.gender
      ) {
        await this.fetchChildren(dataToSend);
        this.$store.commit("modals/SET_SHOW_CHART_POPUP", true);
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/components/global/modal-global";
</style>
