<template>
  <div class="modal" v-click-outside="onClickOutside">
    <div class="modal__wrapper">
      <h2 class="modal__title">{{ $t("create_activity") }}</h2>
      <form @submit.prevent="submitActivity" class="modal__form">
        <div class="modal__form__input">
          <app-input
            :hasErrors="true"
            :class="{ hasError: errors.activityName }"
            class="modal__form__input--type"
            type="text"
            name="activity"
            :label="$t('create_activity')"
            v-model="activityName"
          />
          <p class="error">{{ errors.activityName }}</p>
        </div>
        <div class="modal__btns">
          <app-button
            class="btn btn--cancel"
            type="button"
            @click.native="
              $store.commit('modals/SET_ADD_ACTIVITY_MODAL', false)
            "
            >{{ $t("cancel") }}
          </app-button>
          <app-button class="btn btn--save" type="submit"
            >{{ $t("save") }}
          </app-button>
        </div>
      </form>
      <a
        class="modal__close"
        @click="$store.commit('modals/SET_ADD_ACTIVITY_MODAL', false)"
        ><img src="../../assets/icons/close.svg"
      /></a>
    </div>
  </div>
</template>

<script>
import AppInput from "@/components/global/AppInput.vue";
import AppButton from "@/components/global/AppButton.vue";
import { mapActions } from "vuex";
import api from "@/api/api";
import vClickOutside from "v-click-outside";

export default {
  components: { AppButton, AppInput },
  name: "AddActivityModal",
  data() {
    return {
      activityName: null,
      category: null,
      value: "",
      errors: {
        activityName: "",
      },
    };
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  methods: {
    ...mapActions({
      createActivity: "activities/createActivity",
      getActivities: "activities/getActivities",
    }),
    async submitActivity() {
      if (!this.activityName) {
        this.errors.activityName = this.$t("empty_field");
      } else {
        this.errors.activityName = "";
      }
      if (this.activityName) {
        let dataToSend = {
          name: this.activityName,
        };
        try {
          let {
            data: { name: name, id },
          } = await api({ requiresAuth: true }).post(
            `activity/create/`,
            dataToSend
          );
          this.createActivity({ name, id });
          this.$store.commit("modals/SET_ADD_ACTIVITY_MODAL", false);
          let nextId = 1;
          const notification = {
            type: "success",
            message: this.$t("success_created_activity"),
            id: nextId++,
          };

          this.$store.commit("notification/ADD_NOTIFICATION", notification, {
            root: true,
          });
        } catch (err) {
          if (err.response.data.statusCode === 302) {
            this.errors.activityName = this.$t("activity_exists");
          }
        }
      }
    },
    onClickOutside() {
      this.$store.commit("modals/SET_ADD_ACTIVITY_MODAL", false);
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/components/global/modal-global";
</style>
