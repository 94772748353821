<template>
  <div class="page">
    <h2 class="page__title">{{ $t("list_of_admins") }}</h2>
    <div class="page__search">
      <app-input
        type="search"
        name="search"
        :placeholder="$t('search_admin')"
        :hasErrors="true"
        v-model="search_admins"
        class="page__input__search input--search"
      />
      <app-button
        title="Invite admins"
        type="button"
        class="btn btn--filter"
        @click.native="$store.commit('modals/SET_ADD_ADMIN_MODAL', true)"
        ><font-awesome-icon icon="fa-solid fa-user-plus"
      /></app-button>
    </div>
    <p v-if="admins.length === 0" class="page__table__title">
      {{ $t("no_admins_found") }}
    </p>
    <app-table
      class="page-table"
      :columns="columns"
      :table-data="admins"
      :loading="loading"
      :change-page="pageNumberChange"
      :change-size-page="pageSizeChange"
      :total="totalAdmins"
      :page="page"
      :perPage="perPage"
    />

    <transition name="fade">
      <div class="page__modal__view" v-if="showViewAdminModal">
        <view-admin-modal
          :admin-item="adminItem"
          @getAdminsAgain="getAdminsAgain"
        />
      </div>

      <div class="page__modal__add" v-if="showAddAdminModal">
        <add-admin-modal />
      </div>
    </transition>
    <confirm-dialogue ref="ConfirmDialogue"> </confirm-dialogue>
  </div>
</template>

<script>
import AppButton from "@/components/global/AppButton.vue";
import AppTable from "@/components/global/AppTable.vue";
import AddAdminModal from "@/components/Modal/AddAdminModal.vue";
import AppInput from "@/components/global/AppInput.vue";
import { mapActions, mapGetters } from "vuex";
import ConfirmDialogue from "@/components/global/ConfirmDialogue.vue";
import vClickOutside from "v-click-outside";
import api from "@/api/api";
import ViewAdminModal from "@/components/Modal/ViewAdminListModal.vue";
export default {
  name: "AdminsList",
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    AppTable,
    AppButton,
    AddAdminModal,
    AppInput,
    ConfirmDialogue,
    ViewAdminModal,
  },
  data() {
    return {
      adminItem: {},
      showUser: false,
      showAdd: false,
      loading: true,
      search_admins: "",
      page: 1,
      perPage: 10,
      columns: [
        {
          field: "full_name",
          key: "a",
          title: this.$t("full_name"),
          align: "left",
          width: "40%",
        },
        {
          field: "email",
          key: "b",
          title: this.$t("email"),
          align: "left",
          width: "20%",
        },
        {
          field: "created_at",
          key: "c",
          title: this.$t("created_at"),
          align: "left",
          width: "20%",
          renderBodyCell: ({ row }) => {
            return row.created_at.split("T")[0];
          },
        },
        {
          field: "",
          key: "n",
          align: "left",
          width: "10%",
          renderBodyCell: ({ row }) => {
            return (
              <span>
                <button
                  class={this.hasAcces ? "btn btn--view" : "hideButton"}
                  on-click={() => {
                    this.adminItem = row;
                    this.$store.commit(
                      "modals/SET_SHOW_VIEW_ADMIN_MODAL",
                      true
                    );
                  }}
                >
                  {this.$t("view")}
                </button>
              </span>
            );
          },
        },
        {
          field: "delete",
          key: "d",
          title: "",
          align: "right",
          width: "10%",

          renderBodyCell: ({ row }) => {
            return (
              <span>
                <button
                  class="btn btn--delete-trash"
                  on-click={() => {
                    this.adminItem = row;
                    this.doDelete(row.id);
                  }}
                >
                  {" "}
                  <font-awesome-icon icon="fa-solid fa-trash-can" />
                </button>
              </span>
            );
          },
        },
      ],
    };
  },

  watch: {
    async search_admins(search) {
      if (search !== this.$route.query.search) {
        this.$router.replace({
          query: {
            search,
            page: 1,
            perPage: this.$route.query.perPage,
          },
        });
      }
      if (!search) {
        this.$router.replace({
          query: {
            page: this.$route.query.page,
            perPage: this.$route.query.perPage,
          },
        });
      } else {
        await this.fetchAdmins();
      }
      if (this.search_admins.length > 2) {
        try {
          await this.fetchAdmins();
        } catch (err) {
          console.error("Something went wrong" + err);
        }
      } else {
        await this.fetchAdmins();
      }
    },
    async page(page) {
      if (page !== +this.$route.query.page) {
        this.$router.replace({
          query: {
            search: this.$route.query.search,
            page,
            perPage: this.$route.query.perPage,
          },
        });
      }
      await this.fetchAdmins();
    },
    async perPage(perPage) {
      if (perPage !== +this.$route.query.perPage) {
        this.$router.replace({
          query: {
            page: 1,
            perPage,
          },
        });
      }
      await this.fetchAdmins();
    },
  },
  async mounted() {
    this.hasAcces = this.$store.getters.isSuperAdmin;
    this.loading = true;
    if (this.$route.query.search) {
      this.search_admins = this.$route.query.search;
    }
    if (this.$route.query.page) {
      this.page = +this.$route.query.page;
    }
    if (this.$route.query.perPage) {
      this.perPage = +this.$route.query.perPage;
    }
    await this.fetchAdmins();
    this.loading = false;
  },
  methods: {
    ...mapActions({
      getAllAdmins: "admins/getAllAdmins",
      getPage: "admins/getPage",
      getPerPage: "admins/getPerPage",
    }),

    getAdminsAgain(val) {
      console.log("Hey", val);
      if (val === true) {
        // this.fetchAdmins();
      }
    },
    async doDelete(id) {
      const ok = await this.$refs.ConfirmDialogue.show({
        title: "Delete Page",
        message: this.$t("confirm_delete_user"),
        okButton: this.$t("delete"),
        cancelButton: this.$t("cancel"),
      });
      if (ok) {
        await api({ requiresAuth: true }).delete(`/users/admin/${id}`);
        let nextId = 1;
        const notification = {
          type: "success",
          message: this.$t("success_deleted_user"),
          id: nextId++,
        };
        this.$store.commit("notification/ADD_NOTIFICATION", notification, {
          root: true,
        });
        await this.fetchAdmins();
      }
    },
    onClickOutside() {
      this.$store.commit("modals/SET_ADD_ADMIN_MODAL", false);
    },
    async pageNumberChange(page) {
      this.page = page;
    },
    async pageSizeChange(perPage) {
      this.perPage = perPage;
    },
    async fetchAdmins() {
      await this.getAllAdmins({
        search: this.$route.query.search || this.search_activity,
        page: this.$route.query.page || this.page,
        perPage: this.$route.query.perPage || this.perPage,
      });
    },
  },
  computed: {
    ...mapGetters({
      admins: "admins/admins",
      showAddAdminModal: "modals/showAddAdminModal",
      totalAdmins: "admins/totalAdmins",
      showViewAdminModal: "modals/showViewAdminModal",
    }),
  },
};
</script>

<style lang="scss">
@import "../assets/scss/components/global/page-global";
</style>
