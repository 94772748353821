export default {
  namespaced: true,
  state: {
    showAddClassModal: false,
    showViewClassModal: false,
    showAddUserModal: false,
    showUserDetailsModal: false,
    showActivityModal: false,
    addActivityModal: false,
    showProfileDropdown: false,
    showAddAdminModal: false,
    showSubjectDetailsModal: false,
    showChildDetailsModal: false,
    showViewAdminModal: false,
    showAddSubjectModal: false,
    showFilterPopup: false,
    showChartPopup: false,
  },
  getters: {
    showAddClassModal(state) {
      return state.showAddClassModal;
    },
    showViewClassModal(state) {
      return state.showViewClassModal;
    },
    showUserDetailsModal(state) {
      return state.showUserDetailsModal;
    },
    showAddUserModal(state) {
      return state.showAddUserModal;
    },
    showActivityModal(state) {
      return state.showActivityModal;
    },
    addActivityModal(state) {
      return state.addActivityModal;
    },
    showProfileDropdown(state) {
      return state.showProfileDropdown;
    },
    showAddAdminModal(state) {
      return state.showAddAdminModal;
    },
    showAdminDetailsModal(state) {
      return state.showAdminDetailsModal;
    },
    showSubjectDetailsModal(state) {
      return state.showSubjectDetailsModal;
    },
    showChildDetailsModal(state) {
      return state.showChildDetailsModal;
    },
    showViewAdminModal: (state) => state.showViewAdminModal,
    showAddSubjectModal: (state) => state.showAddSubjectModal,
    showFilterPopup: (state) => state.showFilterPopup,
    showChartPopup: (state) => state.showChartPopup,
  },
  mutations: {
    SET_SHOW_ADD_CLASS_MODAL(state, data) {
      state.showAddClassModal = data;
    },
    SET_SHOW_VIEW_CLASS_MODAL(state, data) {
      state.showViewClassModal = data;
    },
    SET_SHOW_USER_DETAILS_MODAL(state, data) {
      state.showUserDetailsModal = data;
    },
    SET_SHOW_ADD_USER_MODAL(state, data) {
      state.showAddUserModal = data;
    },
    SET_SHOW_ACTIVITY_MODAL(state, data) {
      state.showActivityModal = data;
    },
    SET_ADD_ACTIVITY_MODAL(state, data) {
      state.addActivityModal = data;
    },
    SET_SHOW_PROFILE_DROPDOWN(state, data) {
      state.showProfileDropdown = data;
    },
    SET_ADD_ADMIN_MODAL(state, data) {
      state.showAddAdminModal = data;
    },
    SET_SHOW_ADMIN_DETAILS_MODAL(state, data) {
      state.showAdminDetailsModal = data;
    },
    SET_SHOW_SUBJECT_MODAL(state, data) {
      state.showSubjectDetailsModal = data;
    },
    SET_SHOW_CHILD_DETAILS_MODAL(state, data) {
      state.showChildDetailsModal = data;
    },
    SET_SHOW_VIEW_ADMIN_MODAL(state, data) {
      state.showViewAdminModal = data;
    },
    SET_SHOW_ADD_SUBJECT_MODAL(state, data) {
      state.showAddSubjectModal = data;
    },
    SET_SHOW_FILTER_POPUP(state, data) {
      state.showFilterPopup = data;
    },
    SET_SHOW_CHART_POPUP(state, data) {
      state.showChartPopup = data;
    },
  },
};
