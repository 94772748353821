<template>
  <div class="recover__password">
    <div class="recover__wrapper">
      <img class="recover__wrapper-img" src="../assets/icons/Lock.svg" alt="" />
      <h2 class="recover__wrapper-title">{{ $t("recover_password") }}</h2>
      <form class="recover__form" @submit.prevent="handleSubmit">
        <app-input
          type="email"
          class="recover__wrapper-input"
          :label="$t('email')"
          name=""
          v-model="email"
          :hasErrors="!this.errors.email"
        ></app-input>
        <p v-if="errors.emailMessage" :class="hasError ? 'error' : 'success'">
          {{ errors.emailMessage }}
        </p>
        <app-button class="btn btn--save" type="submit">{{
          $t("recover_password")
        }}</app-button>
      </form>
      <p class="recover__wrapper-back">
        {{ $t("go_back_to")
        }}<router-link :to="{ name: 'AppLogin' }">Login</router-link>
      </p>
    </div>
  </div>
</template>

<script>
import AppInput from "@/components/global/AppInput.vue";
import AppButton from "@/components/global/AppButton.vue";
import api from "@/api/api";
export default {
  name: "RecoverPassword",
  data() {
    return {
      email: "",
      hasError: false,
      errors: {
        emailMessage: null,
      },
    };
  },
  components: {
    AppInput,
    AppButton,
  },
  methods: {
    async handleSubmit() {
      if (!this.email) {
        this.errors.emailMessage = this.$t("enter_a_valid_email");
        this.hasError = true;
      } else {
        this.errors.emailMessage = "";
        this.hasError = false;
      }
      try {
        const response = await api({ requiresAuth: false }).post(
          "/auth/forgetPassword",
          {
            email: this.email,
          }
        );

        if (response.status == 201) {
          this.errors.emailMessage = this.$t("success_sent");
          this.hasError = false;
        }
      } catch (err) {
        console.error(err);
        if (err.message === "Network Error") {
          this.hasError = true;
        }

        if (err.response.status === 404) {
          this.errors.emailMessage = this.$t("invalid_email");
          this.hasError = true;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/pages/recover-password";
</style>
