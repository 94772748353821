import api from "@/api/api";

export default {
  namespaced: true,
  state: {
    admins: [],
    totalAdmins: 0,
  },
  getters: {
    admins: (state) => state.admins,
    totalAdmins: (state) => state.totalAdmins,
  },
  mutations: {
    SET_ALL_ADMINS: (state, data) => {
      state.admins = data;
    },
    SET_TOTAL_ADMINS: (state, data) => {
      state.totalAdmins = data;
    },
    SET_UPDATE_ADMIN: (state, data) => {
      let findAdmin = state.admins.find((admin) => admin.id == data.id);

      findAdmin.full_name = data.full_name;
      findAdmin.email = data.email;
    },
  },
  actions: {
    async getAllAdmins({ commit }, { search, page, perPage }) {
      const params = {};
      if (search) {
        params.search = search;
      }
      if (page) {
        params.page = page;
      }
      if (perPage) {
        params.perPage = perPage;
      }
      try {
        let { data } = await api({ requiresAuth: true }).get(
          `/users/alladmins`,
          { params }
        );
        commit("SET_ALL_ADMINS", data.admins);
        commit("SET_TOTAL_ADMINS", data.total);
      } catch (err) {
        console.error("Something went wrong " + err);
      }
    },
    async updateAdmin({ commit }, adminToUpdate) {
      let { data } = await api({ requiresAuth: true }).put(
        `users/${adminToUpdate.id}/update`,
        adminToUpdate
      );
      console.log(data);
      commit("SET_UPDATE_ADMIN", data);
      return data;
    },
  },
};
