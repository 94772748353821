import api from "@/api/api";

export default {
  namespaced: true,
  state: {
    currentUser: {},
  },
  getters: {
    currentUser: (state) => state.currentUser,
  },
  mutations: {
    SET_CURRENT_USER: (state, data) => {
      state.currentUser = data;
    },
  },
  actions: {
    async getCurrentUser({ commit }) {
      try {
        let { data } = await api({ requiresAuth: true }).get("/users/me");
        commit("SET_CURRENT_USER", data);
      } catch (err) {
        console.error("Something went wrong.Can't get users " + err);
      }
    },
    createUser({ commit }, user) {
      commit("CREATE_USER", user);
    },
    async inviteAdmin(_, email) {
      await api({ requiresAuth: true }).post("/users/invite", email);
    },
  },
};
