<template>
  <div class="lockscreen">
    <div class="lockscreen__wrapper">
      <div class="lockscreen__content">
        <img src="../assets/icons/User-Large.svg" class="lockscreen__img" />
        <h2 class="lockscreen__name">{{ getCurrentUser.full_name }}</h2>
        <p class="lockscreen__desc">
          Enter your password to access the dashboard.
        </p>
      </div>
      <form @submit.prevent="accessDashboard()">
        <app-input
          label="Password"
          id="loginPassword"
          class="lockscreen-password"
          name="password"
          @password-toggle="showPassword = !showPassword"
          v-model="password"
          type="password"
        ></app-input>
        <app-button class="lockscreen-btn btn" type="submit">Unlock</app-button>
      </form>
      <p class="lockscreen__paragraph">
        Not you?
        <router-link class="lockscreen__signin" :to="{ name: 'AppLogin' }"
          >Sign In</router-link
        >
      </p>
    </div>
  </div>
</template>

<script>
import AppInput from "@/components/global/AppInput.vue";
import AppButton from "@/components/global/AppButton.vue";
import { mapGetters } from "vuex";
import api from "@/api/api";
export default {
  components: {
    AppInput,
    AppButton,
  },
  data() {
    return {
      password: "",
      showPassword: false,
      time: 10000,
    };
  },
  mounted() {
    this.getCurrentUser;
  },
  computed: {
    ...mapGetters({
      getCurrentUser: "auth/getCurrentUser",
    }),
  },
  created() {
    let timerId = setInterval(() => {
      this.time -= 1000;
      if (!this.$store.state.idleVue.isIdle) clearInterval(timerId);
      if (this.time < 1) {
        clearInterval(timerId);
        // Your logout function should be over here
        alert("logout user....");
      }
    }, 1000);
  },

  methods: {
    accessDashboard() {
      api({ requiresAuth: false })
        .post("/auth/login", {
          email: this.getCurrentUser.email,
          password: this.password,
        })
        .then((res) => {
          console.log(res.status);
          alert(res.status);

          alert(res);
        })
        .catch((err) => {
          alert(err.response.status);
        });
    },
  },
};
</script>

<style lang="scss">
@import "../assets/scss/pages/_lock-screen.scss";
</style>
