import axios from "axios";
import store from "../store";

const axiosInstance = axios.create({ baseURL: process.env.VUE_APP_BASE_URL });

// const MAX_REQUESTS_COUNT = 5;
// const INTERVAL_MS = 10;
// let PENDING_REQUESTS = 0;

export default ({ requiresAuth }) => {
  if (requiresAuth) {
    axiosInstance.defaults.headers.Authorization = `Bearer ${localStorage.getItem(
      "access_token"
    )}`;
  }

  return axiosInstance;
};

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      store.dispatch("logout").then(() => {
        this.$router.push({ name: "AppLogin" });
      });
    }
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 500) {
      let nextId = 1;
      const notification = {
        type: "error",
        message: this.$t("server_error"),
        id: nextId++,
      };
      this.$store.commit("notification/ADD_NOTIFICATION", notification, {
        root: true,
      });
    }
    return Promise.reject(error);
  }
);

// axiosInstance.interceptors.request.use(function (config) {
//   return new Promise((resolve) => {
//     let interval = setInterval(() => {
//       if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
//         PENDING_REQUESTS++;
//         clearInterval(interval);
//         resolve(config);
//       }
//     }, INTERVAL_MS);
//   });
// });

// axiosInstance.interceptors.response.use(
//   function (response) {
//     PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
//     return Promise.resolve(response);
//   },
//   function (error) {
//     PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
//     return Promise.reject(error);
//   }
// );
