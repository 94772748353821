<template>
  <div class="modal" v-click-outside="onClickOutside">
    <div class="modal__wrapper">
      <h2 class="modal__title">{{ $t("user_details") }}</h2>

      <form @submit.prevent="update" class="modal__form">
        <div class="modal__form__input">
          <app-input
            :label="$t('edit_fullname')"
            :hasErrors="!!errors.fullName"
            :class="{ hasError: errors.fullName }"
            class="modal__form__input--type"
            id="fullName"
            v-model="fullName"
            name="fullname"
            type="text"
          />
          <p class="error" v-if="errors.fullName">{{ errors.fullName }}</p>
          <app-input
            :label="$t('edit_email')"
            :hasErrors="!!errors.email"
            :class="{ hasError: errors.email }"
            class="modal__form__input--type"
            id="email"
            v-model="email"
            name="email"
            type="email"
          />
          <p class="error" v-if="errors.email">{{ errors.email }}</p>
        </div>

        <div class="modal__btns">
          <app-button
            class="btn btn--cancel"
            type="button"
            @click.native="
              $store.commit('modals/SET_SHOW_USER_DETAILS_MODAL', false)
            "
            >{{ $t("cancel") }}
          </app-button>
          <app-button type="submit" class="btn btn--save">
            {{ $t("save") }}
          </app-button>
        </div>
      </form>
      <confirm-dialogue ref="ConfirmDialogue" />
      <a
        class="modal__close"
        @click="$store.commit('modals/SET_SHOW_USER_DETAILS_MODAL', false)"
        ><img src="../../assets/icons/close.svg"
      /></a>
    </div>
  </div>
</template>

<script>
import AppButton from "../global/AppButton.vue";
import ConfirmDialogue from "../global/ConfirmDialogue.vue";
import AppInput from "../global/AppInput.vue";
import { mapActions } from "vuex";
import api from "@/api/api";
import vClickOutside from "v-click-outside";

export default {
  components: { AppButton, ConfirmDialogue, AppInput },
  name: "ViewUser",
  data() {
    return {
      idUser: 0,
      fullName: null,
      email: null,
      errors: {
        fullName: "",
        email: "",
      },
    };
  },
  props: {
    userItem: {
      type: Object,
      required: true,
    },
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  mounted() {
    const { id, full_name, email } = this.userItem;
    this.idUser = id;
    this.fullName = full_name;
    this.email = email;
  },

  methods: {
    ...mapActions({
      users: "users/users",
      updateUser: "users/updateUser",
    }),

    async update() {
      let erromsg = this.$t("empty_field");
      this.errors.fullName = !this.fullName ? erromsg : "";
      this.errors.email = !this.email ? erromsg : "";
      const currentData = this.userItem;

      if (this.fullName !== currentData.full_name) {
        try {
          let data = await api({ requiresAuth: true }).put(
            `users/${this.userItem.id}/update`,
            {
              full_name: this.fullName,
            }
          );
          if (data.request.status === 200) {
            let nextId = 1;
            const notification = {
              type: "success",

              message: this.$t("success_update_name"),

              id: nextId++,
            };

            this.$store.commit("notification/ADD_NOTIFICATION", notification, {
              root: true,
            });
          }
          this.$store.commit("users/UPDATE_USER", data.data);
          this.$store.commit("modals/SET_SHOW_USER_DETAILS_MODAL", false);
        } catch (err) {
          if (err.response.data.message[0]) {
            this.errors.fullName;
          }
        }
      } else {
        if (this.email !== currentData.email) {
          try {
            let data = await api({ requiresAuth: true }).put(
              `users/${this.userItem.id}/update`,
              {
                email: this.email,
              }
            );
            if (data.request.status === 200) {
              let nextId = 1;
              const notification = {
                type: "success",
                message: this.$t("success_update_email"),
                id: nextId++,
              };
              this.$store.commit(
                "notification/ADD_NOTIFICATION",
                notification,
                {
                  root: true,
                }
              );
            }
            this.$store.commit("users/UPDATE_USER", data.data);
            this.$store.commit("modals/SET_SHOW_USER_DETAILS_MODAL", false);
          } catch (err) {
            if (err.response.data.message[0].includes("That email is taken")) {
              console.log(err.response.data.message[0]);
              this.errors.email = err.response.data.message[0];
            }
          }
        }
      }
    },
    onClickOutside() {
      this.$store.commit("modals/SET_SHOW_USER_DETAILS_MODAL", false);
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/components/global/modal-global";
</style>
