import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueEasytable from "vue-easytable";
import "vue-multiselect/dist/vue-multiselect.min.css";
import "./assets/scss/index.scss";
import { VePagination } from "vue-easytable";
import { VeIcon } from "vue-easytable";
import Multiselect from "vue-multiselect";
import "./directives";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import FlagIcon from "vue-flag-icon";
import i18n from "../src/i18n";
import IdleVue from "idle-vue";
const eventsHub = new Vue();

Vue.use(FlagIcon);
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 3000, // 3 seconds
  startAtIdle: false,
});

import {
  faBook,
  faChildren,
  faUser,
  faPersonWalking,
  faXmark,
  faTrashCan,
  faTableCellsLarge,
  faEye,
  faEyeSlash,
  faUsers,
  faArrowRightFromBracket,
  faSliders,
  faPlus,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { faCircleUser as faFileRegular } from "@fortawesome/free-regular-svg-icons";
import { faIdBadge as faFileRegularBadge } from "@fortawesome/free-regular-svg-icons";
Vue.use(VeIcon);
Vue.use(VePagination);
Vue.use(VueEasytable);
Vue.config.productionTip = false;
Vue.component("multi-select", Multiselect);

library.add(
  faChildren,
  faUser,
  faPersonWalking,
  faBook,
  faXmark,
  faTrashCan,
  faTableCellsLarge,
  faEye,
  faEyeSlash,
  faFileRegular,
  faFileRegularBadge,
  faUsers,
  faArrowRightFromBracket,
  faSliders,
  faPlus,
  faUserPlus
);
Vue.config.productionTip = false;

Vue.component("font-awesome-icon", FontAwesomeIcon);
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
