import api from "@/api/api";

export default {
  // namespaced: true,
  state: {
    user: window.localStorage.getItem("user")
      ? JSON.parse(window.localStorage.getItem("user"))
      : null,
  },
  getters: {
    loggedIn(state) {
      return !!state.user;
    },
    getCurrentUser(state) {
      return state.user;
    },
    isSuperAdmin(state) {
      return state.user.role === "superadmin";
    },
  },
  mutations: {
    SET_USER_DATA(state, userData) {
      state.user = userData;
    },
    CLEAR_USER_TOKEN() {
      localStorage.removeItem("access_token");
      location.reload();
    },
  },
  actions: {
    async login(_, credLogin) {
      return await api({ requiresAuth: false }).post("/auth/login", credLogin);
    },

    async signup(_, { token, ...credentials }) {
      return api({ requiresAuth: false }).post(
        `/auth/register/${token}`,
        credentials
      );
    },
    logout({ commit }) {
      commit("CLEAR_USER_TOKEN");
    },
    async resetPassword(_, { token, ...passwordData }) {
      await api({ requiresAuth: false }).post(
        `/auth/resetPassword/${token}`,
        passwordData
      );
    },
    async changePassword(_, passwordData) {
      return await api({ requiresAuth: true }).patch(
        "/users/change-password",
        passwordData
      );
    },
  },
};
