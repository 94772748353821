<template>
  <main class="signup__starter">
    <div class="signup__starter__wrapper">
      <div class="signup__starter__wrapper__image">
        <img
          class="signup__starter__wrapper__image--bg"
          src="../assets/images/pattern.png"
          alt="Login Picture"
        />
      </div>
      <div class="signup__wrapper">
        <div class="signup">
          <h2 class="signup__title">{{ $t("create_account") }}</h2>
          <form class="signup__form" @submit.prevent="signup()">
            <p class="signup__email">SIGN UP WITH EMAIL</p>
            <div class="signup__form__input">
              <app-input
                class="error--input"
                type="text"
                :label="$t('full_name')"
                id="loginFullName"
                name="fullName"
                v-model="fullName"
                :class="{ hasError: errors.fullName }"
                :hasErrors="!!errors.fullName"
              ></app-input>
              <p class="error">
                {{ errors.fullName }}
              </p>
            </div>
            <div class="signup__form__input">
              <app-input
                type="text"
                :label="$t('email')"
                id="loginEmail"
                name="email"
                required
                v-model="email"
                :class="{ hasError: errors.email }"
                :hasErrors="!!errors.email"
              ></app-input>
              <p class="error">
                {{ errors.email }}
              </p>
            </div>
            <div class="signup__form__input">
              <app-input
                type="text"
                label="Username"
                id="userName"
                name="userName"
                v-model="userName"
                :class="{ hasError: errors.userName }"
                :hasErrors="!!errors.userName"
              ></app-input>
              <p class="error">
                {{ errors.userName }}
              </p>
            </div>
            <div class="signup__form__input">
              <app-input
                :type="showPassword ? 'text' : 'password'"
                :label="$t('password')"
                id="loginPassword"
                name="password"
                password
                @password-toggle="showPassword = !showPassword"
                :showPassword="showPassword"
                v-model="password"
                :class="{ hasError: errors.password }"
                :hasErrors="!!errors.password"
              ></app-input>
              <p class="error">
                {{ errors.password }}
              </p>
            </div>
            <div class="signup__form__input">
              <app-input
                :type="showConfirmPassword ? 'text' : 'password'"
                :label="$t('confirm_password')"
                id="signupConfirmPassword"
                name="confirmPassword"
                password
                @password-toggle="showConfirmPassword = !showConfirmPassword"
                :showPassword="showConfirmPassword"
                v-model="confirmPassword"
                :class="{ hasError: errors.confirmPassword }"
                :hasErrors="!!errors.confirmPassword"
              ></app-input>
              <p class="error">
                {{ errors.confirmPassword }}
              </p>
            </div>
            <app-button
              class="btn btn--signup"
              type="submit"
              :isLoading="isLoading"
              >{{ $t("sign_up") }}</app-button
            >
          </form>
          <p class="sign-in-footer">
            {{ $t("already_have_account") }}
            <router-link :to="{ name: 'AppLogin' }"> Sign In </router-link>
          </p>
          <FlagButtonVue />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import AppButton from "@/components/global/AppButton.vue";
import AppInput from "@/components/global/AppInput.vue";
import { mapActions } from "vuex";
import NProgress from "nprogress";
import FlagButtonVue from "@/components/global/FlagButton.vue";
export default {
  name: "AppSignUp",
  components: {
    AppButton,
    AppInput,
    FlagButtonVue,
  },
  data() {
    return {
      inputError: false,
      fullName: "",
      isLoading: false,
      userName: "",
      email: "",
      password: "",
      confirmPassword: "",
      showPassword: false,
      showConfirmPassword: false,
      errors: {
        fullName: "",
        userName: "",
        email: "",
        password: "",
        confirmPassword: "",
      },
    };
  },
  watch: {
    email(value) {
      this.email = value;
      this.validateEmail(value);
    },
    userName(name) {
      this.errors.userName = name.length < 6 ? this.$t("short_username") : "";
    },
    password(pw) {
      this.errors.password = pw.length < 6 ? this.$t("short_password") : "";
    },
    confirmPassword(confirmP) {
      if (confirmP.length < 6) {
        this.errors.confirmPassword = this.$t("short_password");
      } else if (this.password !== this.confirmPassword) {
        this.errors.confirmPassword = this.$t("password_not_match");
      } else {
        this.errors.confirmPassword = "";
      }
    },
  },
  methods: {
    ...mapActions({
      addNotification: "notification/ADD_NOTIFICATION",
    }),
    validateEmail(email) {
      //eslint-disable-next-line
      const reg = /^\w+([\.-]?i\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (reg.test(email)) {
        this.errors.email = "";
      } else {
        this.errors.email = this.$t("invalid_email_msg");
      }
    },
    async signup() {
      this.errors.userName = !this.userName ? this.$t("empty_field") : "";
      this.errors.fullName = !this.fullName ? this.$t("empty_field") : "";
      this.errors.email = !this.email ? this.$t("empty_field") : "";
      this.errors.password = !this.password ? this.$t("empty_field") : "";
      this.errors.confirmPassword = !this.confirmPassword
        ? this.$t("empty_field")
        : "";

      if (!this.confirmPassword) {
        this.errors.confirmPassword = this.$t("empty_field");
      } else {
        this.errors.fullName = "";
        this.errors.userName = "";
        this.errors.email = "";
        this.errors.password = "";
        this.errors.confirmPassword = "";
      }
      this.isLoading = true;
      if (
        this.fullName &&
        this.email &&
        this.userName &&
        this.password &&
        this.confirmPassword
      ) {
        try {
          NProgress.start();
          const {
            data: { user, access_token },
          } = await this.$store.dispatch("signup", {
            full_name: this.fullName,
            email: this.email,
            username: this.userName,
            password: this.password,
            password_confirm: this.confirmPassword,
            token: this.$route.params.token,
          });
          this.$store.commit("SET_USER_DATA", user);
          localStorage.setItem("access_token", access_token);
          this.$router.push({ name: "LandingPage" });
          let nextId = 1;
          const notification = {
            type: "success",
            message: this.$t("success_created_user"),
            id: nextId++,
          };
          this.$store.commit("notification/ADD_NOTIFICATION", notification, {
            root: true,
          });
        } catch (err) {
          console.log(err);
          NProgress.done();
          let errorArray = [];
          console.log(err.response.status);
          if (err.response.status === 400) {
            errorArray = [...err.response.data.message];
            errorArray.forEach((error) => {
              if (error.includes("email ")) {
                this.errors.email = this.$t("email_taken");
              } else if (error.includes("username is taken")) {
                this.errors.userName = this.$t("username_taken");
              } else if (
                error.includes("Password confirmation doesn't match.")
              ) {
                this.errors.confirmPassword = this.$t("password_not_match");
              } else {
                this.errors.userName = "";
                this.errors.email = "";
                this.errors.confirmPassword = "";
              }
            });
          }

          let nextId = 1;
          const notification = {
            type: "error",
            message: this.$t("problem_creating_user"),
            id: nextId++,
          };
          this.$store.commit("notification/ADD_NOTIFICATION", notification, {
            root: true,
          });
        }
      }
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/pages/app-signup";
</style>
