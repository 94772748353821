import api from "@/api/api";

export default {
  namespaced: true,
  state: {
    users: [],
    userRoles: [],
    loading: false,
    totalUsers: 0,
  },
  getters: {
    users: (state) => state.users,
    filteredUsers: (state) => state.users.filteredUsers,
    loadingUsers: (state) => state.loading,
    userRoles: (state) => state.userRoles,
    totalUsers: (state) => state.totalUsers,
  },
  mutations: {
    SET_ALL_USERS: (state, data) => {
      state.users = data;
    },
    CREATE_USER(state, user) {
      state.users.push(user);
    },

    UPDATE_USER(state, { id, full_name, email }) {
      let findUser = state.users.find((users) => users.id == id);
      findUser.full_name = full_name;
      findUser.email = email;
    },
    SET_FILTERED_USERS(state, data) {
      state.users = data;
    },
    SET_USER_ROLES(state, data) {
      console.log(data);
      state.userRoles = data;
    },
    SET_TOTAL_USERS: (state, data) => {
      state.totalUsers = data;
    },
  },
  actions: {
    async getAllUsers({ commit }, { search, page, perPage }) {
      const params = {};
      if (search) {
        params.search = search;
      }
      if (page) {
        params.page = page;
      }
      if (perPage) {
        params.perPage = perPage;
      }
      try {
        this.state.loading = true;
        let { data } = await api({ requiresAuth: true }).get(`/users`, {
          params,
        });
        commit("SET_ALL_USERS", data.users);
        commit("SET_TOTAL_USERS", data.total);
        this.state.loading = false;
      } catch (err) {
        console.error("Something went wrong.Can't get users " + err);
      }
    },
    async updateUser({ commit }, data) {
      commit("UPDATE_USER", data);
    },
    async deleteUser(state, id) {
      try {
        await api({ requiresAuth: true }).delete(`/users/${id}/remove`);
      } catch (err) {
        console.error("There was a problem deleting user " + err);
      }
    },
  },
};
