<template>
  <div class="page">
    <h2 class="page__title">{{ $t("list_of_subjects") }}</h2>
    <div class="page__search">
      <app-input
        type="search"
        name="search"
        :placeholder="$t('search_subject')"
        :hasErrors="true"
        v-model="search_class"
        ref="search_class"
        class="page__input__search input--search"
      />
      <app-button
        :class="this.hasAcces ? 'btn btn--add' : 'hideButton'"
        type="button"
        @click.native="$store.commit('modals/SET_SHOW_ADD_CLASS_MODAL', true)"
        ><font-awesome-icon icon="fa-solid fa-plus"
      /></app-button>
    </div>
    <p v-if="classesList.length === 0" class="page__table__title">
      {{ $t("no_subjects_found") }}
    </p>
    <app-table
      class="page-table"
      :columns="tableColumns"
      :table-data="classesList"
      :change-page="pageNumberChange"
      :change-size-page="pageSizeChange"
      :total="totalClasses"
      :page="page"
      :loading="loading"
      :perPage="perPage"
    />
    <confirm-dialogue ref="ConfirmDialogue" />
    <transition name="fade">
      <div class="page__modal__view" v-if="showViewClassModal">
        <view-class-modal :classItem="classItem"></view-class-modal>
      </div>

      <div class="page__modal__add" v-if="showAddClassModal">
        <add-class-modal :classItem="classItem"></add-class-modal>
      </div>
    </transition>
  </div>
</template>

<script>
import AppTable from "@/components/global/AppTable.vue";
import ConfirmDialogue from "@/components/global/ConfirmDialogue.vue";
import ViewClassModal from "@/components/Modal/ViewClassModal.vue";
import AppInput from "@/components/global/AppInput.vue";
import { mapActions, mapGetters } from "vuex";
import api from "@/api/api";
import AppButton from "@/components/global/AppButton.vue";
import AddClassModal from "@/components/Modal/AddClassModal.vue";

export default {
  name: "UserClasses",
  components: {
    AppTable,
    ConfirmDialogue,
    AppInput,
    ViewClassModal,
    AppButton,
    AddClassModal,
  },
  data() {
    return {
      classItem: {},
      viewClass: false,
      showUser: false,
      addClass: false,
      search_class: "",
      loading: false,
      hasAcces: true,
      page: 1,
      perPage: 10,
    };
  },
  watch: {
    async search_class(search) {
      if (search !== this.$route.query.search) {
        this.$router.replace({
          query: {
            search,
            page: 1,
            perPage: this.$route.query.perPage,
          },
        });
      }
      if (!search) {
        this.$router.replace({
          query: {
            page: this.$route.query.page,
            perPage: this.$route.query.perPage,
          },
        });
      } else {
        await this.fetchClasses();
      }
      if (this.search_class.length > 2) {
        try {
          await this.fetchClasses();
        } catch (err) {
          console.error("Something went wrong " + err);
        }
      } else {
        await this.fetchClasses();
      }
    },
    async page(page) {
      if (page !== +this.$route.query.page) {
        this.$router.replace({
          query: {
            search: this.$route.query.search,
            page,
            perPage: this.$route.query.perPage,
          },
        });
      }
      await this.fetchClasses();
    },
    async perPage(perPage) {
      if (perPage !== +this.$route.query.perPage) {
        this.$router.replace({
          query: {
            page: 1,
            perPage,
          },
        });
      }
      await this.fetchClasses();
    },
  },
  async mounted() {
    this.hasAcces = this.$store.getters.isSuperAdmin;
    this.loading = true;
    if (this.$route.query.search) {
      this.search_class = this.$route.query.search;
    }
    if (this.$route.query.page) {
      this.page = +this.$route.query.page;
    }
    if (this.$route.query.perPage) {
      this.perPage = +this.$route.query.perPage;
    }
    await this.fetchClasses();
    this.loading = false;
  },
  methods: {
    ...mapActions({
      getClasses: "classesList/getClasses",
    }),
    async doDelete(id) {
      const ok = await this.$refs.ConfirmDialogue.show({
        title: "Delete Page",
        message: this.hasAcces
          ? this.$t("delete_subject")
          : "You don't have access to delete!",
        okButton: this.$t("delete"),
        cancelButton: this.$t("cancel"),
      });
      if (ok) {
        await api({ requiresAuth: true }).delete(`subject/remove/${id}`);
        let nextId = 1;
        const notification = {
          type: "success",
          message: this.$t("success_deleted_subject"),
          id: nextId++,
        };
        this.$store.commit("notification/ADD_NOTIFICATION", notification, {
          root: true,
        });
        await this.fetchClasses();
      }
    },
    pageNumberChange(page) {
      this.page = page;
    },
    pageSizeChange(perPage) {
      this.perPage = perPage;
    },
    async fetchClasses() {
      await this.getClasses({
        search: this.$route.query.search || this.search_class,
        page: this.$route.query.page || this.page,
        perPage: this.$route.query.perPage || this.perPage,
      });
    },
  },
  computed: {
    ...mapGetters({
      classesList: "classesList/classesList",
      totalClasses: "classesList/totalClasses",
      showViewClassModal: "modals/showViewClassModal",
      showAddClassModal: "modals/showAddClassModal",
    }),
    tableColumns() {
      return [
        {
          field: "subject",
          key: "subject",
          title: this.$t("subjects"),
          align: "left",
          width: "80%",
        },
        {
          field: "",
          key: "b",
          title: "",
          align: "left",
          width: "10%",
          renderBodyCell: ({ row }) => {
            return (
              <span>
                <button
                  class={this.hasAcces ? "btn btn--view" : "hideButton"}
                  on-click={() => {
                    this.classItem = row;
                    this.$store.commit(
                      "modals/SET_SHOW_VIEW_CLASS_MODAL",
                      true
                    );
                  }}
                >
                  {this.$t("view")}
                </button>
              </span>
            );
          },
        },
        {
          field: "",
          key: "d",
          title: "",
          align: "right",
          width: "10%",
          renderBodyCell: (row) => {
            return (
              <span>
                <button
                  class={this.hasAcces ? "btn btn--delete-trash" : "hideButton"}
                  on-click={() => {
                    this.doDelete(row.row.id);
                  }}
                >
                  {" "}
                  <font-awesome-icon icon="fa-solid fa-trash-can" />
                </button>
              </span>
            );
          },
        },
      ];
    },
  },
};
</script>

<style lang="scss">
// @import "../assets/scss/pages/user-classes";
@import "../assets/scss/components/global/page-global";
</style>
