<template>
  <div class="super-admin">
    <div class="super-admin__wrapper" :class="getCurrentMode">
      <div class="super-admin__content">
        <div class="super-admin__content__first-letter">
          {{ fullName?.charAt(0).toUpperCase() }}
        </div>
        <div class="super-admin__info">
          <h2 class="super-admin__info__name">{{ fullNamee }}</h2>
          <p class="super-admin__info__role">{{ role.toUpperCase() }}</p>
        </div>
      </div>
      <form class="super-admin__form" @submit.prevent="submitEdit">
        <h3 class="super-admin__form__title">INFO</h3>
        <app-input
          v-model="fullNamee"
          name="fullName"
          type="text"
          :label="$t('full_name').toUpperCase()"
          :class="this.onEdit ? 'focus-input' : ''"
          :hasErrors="!!errors.fullName"
        ></app-input>
        <p class="error" v-if="errors.fullName">
          {{ this.errors.fullName }}
        </p>
        <app-input
          type="email"
          class="super-adm-email"
          name="email"
          v-model="emaill"
          :label="$t('email').toUpperCase()"
          :hasErrors="!!errors.email"
          :class="this.onEdit ? 'focus-input' : ''"
        ></app-input>
        <p class="error" v-if="errors.email">{{ errors.email }}</p>
        <p class="error" v-if="errors.generalMsg">{{ errors.generalMsg }}</p>

        <div class="super-admin__form__btns">
          <app-button class="btn btn--edit" type="submit">{{
            $t("submit_edit")
          }}</app-button>
          <app-button
            @click.native="$router.push('/change-password')"
            class="btn btn--save"
            type="none"
          >
            {{ $t("change_password") }}
          </app-button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import AppInput from "@/components/global/AppInput.vue";
import AppButton from "@/components/global/AppButton.vue";
import { mapActions, mapGetters } from "vuex";
import api from "@/api/api";
export default {
  components: {
    AppInput,
    AppButton,
  },

  data() {
    return {
      fullNamee: "",
      emaill: "",
      hasAccess: true,
      onEdit: false,
      errors: {
        fullName: "",
        email: "",
        generalMsg: "",
      },
    };
  },
  async mounted() {
    this.hasAccess = this.$store.getters.isSuperAdmin;
    await this.getCurrentUser();

    this.fullNamee = this.currentUser?.full_name;
    this.emaill = this.currentUser?.email;
  },

  watch: {
    fullNamee() {
      this.errors.fullName =
        this.fullNamee.length < 1 ? this.$t("empty_field") : "";
    },
    emaill() {
      this.errors.email = this.emaill.length < 1 ? this.$t("empty_field") : "";
    },
  },

  computed: {
    ...mapGetters({
      currentUser: "superadmin/currentUser",
      getCurrentMode: "switcher/getCurrentMode",
    }),

    role() {
      return this.$store.getters.getCurrentUser.role;
    },
    fullName() {
      return this.currentUser.full_name || "No User ";
    },
    email() {
      return this.currentUser.email;
    },
  },
  methods: {
    ...mapActions({
      getCurrentUser: "superadmin/getCurrentUser",
    }),
    async submitEdit() {
      this.errors.email = this.emaill ? "" : this.$t("empty_field");
      this.errors.fullName = this.fullNamee ? "" : this.$t("empty_field");

      if (this.emaill || this.fullNamee) {
        if (this.emaill !== this.currentUser.email) {
          let res = await api({ requiresAuth: true }).put(
            `users/${this.currentUser.id}/update`,
            {
              full_name: this.fullNamee,
              email: this.emaill,
            }
          );
          if (res.request.status === 200) {
            let nextId = 1;
            const notification = {
              type: "success",
              message: this.$t("success_update_email"),
              id: nextId++,
            };
            this.$store.commit("notification/ADD_NOTIFICATION", notification, {
              root: true,
            });
            this.getCurrentUser();
          }
        }

        if (this.fullNamee !== this.currentUser.full_name) {
          try {
            let res = await api({ requiresAuth: true }).put(
              `users/${this.currentUser.id}/update`,
              {
                full_name: this.fullNamee,
              }
            );
            if (res.request.status === 200) {
              let nextId = 1;
              const notification = {
                type: "success",
                message: this.$t("success_update_name"),
                id: nextId++,
              };
              this.$store.commit(
                "notification/ADD_NOTIFICATION",
                notification,
                {
                  root: true,
                }
              );
              this.getCurrentUser();
            }
          } catch (err) {
            console.error(err);
          }
        }
      }
    },
  },
};
</script>
<style lang="scss">
@import "../assets/scss/pages/_super-admin-profile.scss";
</style>
