<template>
  <div class="modal" v-click-outside="onClickOutside">
    <div class="modal__wrapper">
      <h2 class="modal__title">{{ $t("add_subject") }}</h2>
      <form @submit.prevent="addSubject" class="modal__form">
        <div class="modal__form__input">
          <app-input
            :hasErrors="true"
            :class="{ hasError: errors.subjectName }"
            class="modal__form__input--type"
            type="text"
            name="about-cls"
            :label="$t('add_subject')"
            v-model="subjectName"
          />
          <p class="error">{{ errors.subjectName }}</p>
        </div>
        <div class="modal__btns">
          <app-button
            @click.native="
              $store.commit('modals/SET_SHOW_ADD_CLASS_MODAL', false)
            "
            type="button"
            class="btn btn--cancel"
            >{{ $t("cancel") }}
          </app-button>
          <app-button class="btn btn--save" type="submit"
            >{{ $t("save") }}
          </app-button>
        </div>
      </form>
      <a
        class="modal__close"
        @click="$store.commit('modals/SET_SHOW_ADD_CLASS_MODAL', false)"
        ><img src="../../assets/icons/close.svg"
      /></a>
    </div>
  </div>
</template>

<script>
import AppInput from "@/components/global/AppInput.vue";
import AppButton from "@/components/global/AppButton.vue";
import { mapActions, mapGetters } from "vuex";
import api from "@/api/api";
import vClickOutside from "v-click-outside";

export default {
  components: { AppButton, AppInput },
  name: "AddClassModal",
  data() {
    return {
      subjectName: null,
      errors: {
        subjectName: "",
      },
    };
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  computed: {
    ...mapGetters({
      showAddClassModal: "modals/showAddClassModal",
    }),
  },
  methods: {
    ...mapActions({
      createClass: "classesList/createClass",
    }),
    async addSubject() {
      this.errors.subjectName = !this.subjectName ? this.$t("empty_field") : "";
      if (this.subjectName) {
        let dataToSend = {
          name: this.subjectName,
        };
        try {
          let {
            data: { name: subject, id },
          } = await api({ requiresAuth: true }).post(
            "subject/create",
            dataToSend
          );
          this.createClass({ subject, id });
          this.$store.commit("modals/SET_ADD_ACTIVITY_MODAL", false);
          let nextId = 1;
          const notification = {
            type: "success",
            message: "Succesfully added subject.",
            id: nextId++,
          };

          this.$store.commit("notification/ADD_NOTIFICATION", notification, {
            root: true,
          });
          this.$store.commit("modals/SET_SHOW_ADD_CLASS_MODAL", false);
        } catch (err) {
          if (err.response.status === 400) {
            this.errors.subjectName = this.$t("subject_exists");
          }
        }
      }
    },
    onClickOutside() {
      this.$store.commit("modals/SET_SHOW_ADD_CLASS_MODAL", false);
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/components/global/modal-global";
</style>
