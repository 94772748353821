<template>
  <main class="overlay">
    <div class="dashboard container">
      <router-link class="dashboard__link--logo" to="/Charts">
        <img
          class="dashboard__img--logo"
          src="../assets/icons/neat-logo.png"
          alt="Dashboard"
      /></router-link>
      <router-link
        class="dashboard__link"
        to="/Charts"
        :class="{ active: isActive }"
        ><font-awesome-icon
          class="dashboard__img"
          icon="fa-solid fa-table-cells-large"
        />{{ $t("dashboard") }}</router-link
      >
      <router-link class="dashboard__link" to="/users">
        <font-awesome-icon icon="fa-solid fa-user" class="dashboard__img" />
        {{ $t("users") }}</router-link
      >
      <router-link class="dashboard__link" :to="{ name: 'ChildrenList' }">
        <font-awesome-icon
          icon="fa-solid fa-children"
          class="dashboard__img"
        />{{ $t("children") }}
      </router-link>

      <router-link class="dashboard__link" :to="{ name: 'ActivitiesList' }">
        <font-awesome-icon
          icon="fa-solid fa-person-walking"
          class="dashboard__img"
        />
        {{ $t("activities") }}</router-link
      >

      <router-link class="dashboard__link" :to="{ name: 'UserClasses' }">
        <font-awesome-icon icon="fa-solid fa-book" class="dashboard__img" />
        {{ $t("subjects") }}</router-link
      >
    </div>
    <!-- <lock-user v-if="isIdle" /> -->

    <div class="dashboard__right">
      <div class="dashboard__right__menu">
        <night-switch @toggle="$emit('toggle', $event)" />
        <div>
          <button
            v-for="entry in languages"
            :key="entry.title"
            @click="changeLocale(entry.language)"
            class="btn--lang"
          >
            <flag :iso="entry.flag" v-bind:squared="false" />
            {{ entry.title.slice(0, 2).toUpperCase() }}
          </button>
        </div>
        <div
          @click="
            $store.commit(
              'modals/SET_SHOW_PROFILE_DROPDOWN',
              !showProfileDropdown
            )
          "
          class="dashboard__right--profile-icon"
        >
          {{ this.currentUser.full_name?.charAt(0).toUpperCase() }}
        </div>

        <transition name="fade">
          <profile-dropdown v-if="showProfileDropdown"></profile-dropdown>
        </transition>
      </div>
      <div class="view">
        <transition name="fade" mode="out-in">
          <router-view> </router-view>
        </transition>
      </div>
    </div>
  </main>
</template>

<script>
import ProfileDropdown from "../components/Modal/ProfileDropdown.vue";
import { mapGetters, mapActions } from "vuex";
import NightSwitch from "./NightSwitch.vue";
// import LockUser from "./LockUser.vue";

export default {
  components: { ProfileDropdown, NightSwitch },
  data() {
    return {
      fullName: "",
      isActive: true,
      timeoutID: 0,
      languages: [
        { flag: "us", language: "en", title: "English" },
        { flag: "al", language: "al", title: "Albanian" },
      ],
    };
  },
  async mounted() {
    await this.getCurrentUser();
    this.fullName = (await this.currentUser.full_name)
      ? await this.currentUser.fullName
      : "No User";
  },
  computed: {
    ...mapGetters({
      showProfileDropdown: "modals/showProfileDropdown",
      currentUser: "superadmin/currentUser",
    }),

    // isIdle() {
    //   return this.$store.state.idleVue.isIdle;
    // },
  },

  methods: {
    ...mapActions({
      getCurrentUser: "superadmin/getCurrentUser",
    }),
    logout() {
      this.$store.dispatch("logout");
    },
    onChartsPage() {
      return this.$route.name == "DoughnutChart";
    },
    changeLocale(locale) {
      this.$i18n.locale = locale;
      localStorage.setItem("lang", locale);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/pages/_landing-page.scss";
</style>
