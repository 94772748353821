<template>
  <div class="modal" v-click-outside="onClickOutside">
    <div class="modal__wrapper">
      <h2 class="modal__title">{{ $t("edit_activity") }}</h2>
      <form @submit.prevent="update" class="modal__form">
        <div class="modal__form__input">
          <app-input
            :hasErrors="!!errors.activity"
            :class="{ hasError: errors.activity }"
            class="modal__form__input--type"
            type="text"
            name="activity"
            :label="$t('edit_activity_name')"
            v-model="activity"
          />
          <p class="error">{{ errors.activity }}</p>
          <p class="error">{{ errors.msg }}</p>
        </div>
        <div class="modal__btns">
          <app-button
            @click.native="
              $store.commit('modals/SET_SHOW_ACTIVITY_MODAL', false)
            "
            class="btn btn--cancel"
            type="button"
            >{{ $t("cancel") }}
          </app-button>
          <app-button class="btn btn--save" type="submit">
            {{ $t("save") }}
          </app-button>
        </div>
      </form>
      <a
        class="modal__close"
        @click="$store.commit('modals/SET_SHOW_ACTIVITY_MODAL', false)"
        ><img src="../../assets/icons/close.svg"
      /></a>
      <confirm-dialogue ref="ConfirmDialogue" />
    </div>
  </div>
</template>

<script>
import AppInput from "@/components/global/AppInput.vue";
import AppButton from "@/components/global/AppButton.vue";
import ConfirmDialogue from "../global/ConfirmDialogue.vue";
import { mapActions } from "vuex";
import api from "@/api/api";
import vClickOutside from "v-click-outside";

export default {
  components: { AppButton, AppInput, ConfirmDialogue },
  name: "showActivityModal",
  data() {
    return {
      idActivity: 0,
      show: true,
      activity: "",
      value: "",
      errors: {
        activity: "",
        msg: "",
      },
    };
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    activityItem: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    const { id, name } = this.activityItem;
    this.activity = name;
    this.idActivity = id;
  },
  methods: {
    onClickOutside() {
      this.$store.commit("modals/SET_SHOW_ACTIVITY_MODAL", false);
    },
    ...mapActions({
      getActivities: "activities/getActivities",
      updateActivity: "activities/updateActivity",
    }),
    async update() {
      this.errors.activity = !this.activity ? this.$t("empty_field") : "";

      if (this.activity) {
        let dataToSend = {
          name: this.activity,
          id: this.idActivity,
        };
        try {
          await api({ requiresAuth: true }).put(
            `activity/update/${dataToSend.id}`,
            { name: this.activity }
          );
          this.updateActivity(dataToSend);
          let nextId = 1;
          const notification = {
            type: "success",
            message: this.$t("success_update_activity"),
            id: nextId++,
          };

          this.$store.commit("notification/ADD_NOTIFICATION", notification, {
            root: true,
          });
          this.$store.commit("modals/SET_SHOW_ACTIVITY_MODAL", false);
        } catch (err) {
          if (err.response.data.statusCode === 400) {
            this.errors.activity = this.$t("activity_exists");
          }
        }
      }
    },
  },
};
</script>
<style lang="scss">
@import "../../assets/scss/components/global/modal-global";
</style>
