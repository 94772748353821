<template>
  <div class="chart">
    <div class="chart__wrapper">
      <h2 class="chart__title">{{ $t("charts") }}</h2>
      <div class="children-charts">
        <div class="chart__item">
          <h2 class="chart__item-title">{{ $t("children") }}</h2>
          <Doughnut
            :chart-options="chartOptions"
            :chart-data="chartData"
            :chart-id="chartId"
            :dataset-id-key="datasetIdKey"
            :plugins="plugins"
            :css-classes="cssClasses"
            :styles="styles"
            :width="width"
            :height="height"
          />
          <p class="chart__item__center">
            {{ chartData.datasets[0].data[0] }}<br />
            <span>Total</span>
          </p>
        </div>

        <div class="chart__item">
          <h2 class="chart__item__title">{{ $t("classes") }}</h2>
          <Doughnut
            :chart-options="chartOptions"
            :chart-data="chartDataClasses"
            :chart-id="chartIdClass"
            :dataset-id-key="datasetIdKeyClass"
            :plugins="plugins"
            :css-classes="cssClasses"
            :styles="styles"
            :width="width"
            :height="height"
          />
        </div>
        <div class="chart__item">
          <h2 class="chart__item__title">{{ $t("children_schedule") }}</h2>
          <Doughnut
            :chart-options="chartOptions"
            :chart-data="chartschoolData"
            :chart-id="chartId"
            :dataset-id-key="datasetIdKey"
            :plugins="plugins"
            :css-classes="cssClasses"
            :styles="styles"
            :width="width"
            :height="height"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Doughnut } from "vue-chartjs/legacy";
import { mapActions } from "vuex";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from "chart.js";
import { mapGetters } from "vuex";

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

export default {
  name: "DoughnutChart",
  components: {
    Doughnut,
  },
  props: {
    chartId: {
      type: String,
      default: "doughnut-chart",
    },
    chartIdClass: {
      type: String,
      default: "doughnut-chart-class",
    },

    datasetIdKey: {
      type: String,
      default: "label",
    },
    datasetIdKeyClass: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => ({}),
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      chartData: {
        labels: [
          this.$t("all_children"),
          this.$t("female"),
          this.$t("male"),
          this.$t("under_10"),
          this.$t("over_10"),
        ],

        datasets: [
          {
            cutoutPercentage: 10,
            backgroundColor: [
              "#44CB7F",
              "#FF9054",
              "#DD1B16",
              "#3A63DE",
              "purple",
            ],
            data: [],
          },
        ],
      },
      chartDataClasses: {
        labels: [this.$t("under_4"), this.$t("over_4")],
        datasets: [
          {
            backgroundColor: ["#37CFD1", "#FF955C"],
            data: [],
          },
        ],
      },
      chartschoolData: {
        labels: [this.$t("activities"), this.$t("subjects")],
        datasets: [
          {
            backgroundColor: ["#44CB7F", "#FF9054"],
            data: [],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  async mounted() {
    await this.getChildrenStats();
    await this.renderAllStats();
  },
  methods: {
    ...mapActions({
      getChildrenStats: "statistics/getChildrenStats",
    }),

    renderAllStats() {
      for (const [key, value] of Object.entries(this.getChildStats)) {
        if (key === "under_fourclass" || key === "over_fourclass") {
          this.chartDataClasses.datasets[0].data.push(value);
        }
        if (key === "childrenWith_activity" || key === "childrenWith_school") {
          this.chartschoolData.datasets[0].data.push(value);
        }
        if (
          key === "allChildren" ||
          key === "female" ||
          key === "male" ||
          key === "under_ten" ||
          key === "over_ten"
        ) {
          this.chartData.datasets[0].data.push(value);
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      users: "users/users",
      getChildStats: "statistics/getChildStats",
      getCurrentMode: "switcher/getCurrentMode",
    }),
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/pages/_statistics.scss";
</style>
