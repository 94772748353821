<template>
  <div class="modal" v-click-outside="onClickOutside">
    <div class="modal__wrapper">
      <h2 class="modal__title">{{ $t("admin_details") }}</h2>

      <form @submit.prevent="update" class="modal__form">
        <div class="modal__form__input">
          <app-input
            :label="$t('edit_fullname')"
            :hasErrors="!!errors.fullName"
            :class="{ hasError: errors.fullName }"
            class="modal__form__input--type"
            placeholder="Full Name"
            id="fullName"
            v-model="fullName"
            name="fullname"
            type="text"
          />
          <p class="error">{{ errors.fullName }}</p>
          <app-input
            :label="$t('edit_email')"
            :hasErrors="!!errors.email"
            :class="{ hasError: errors.email }"
            class="modal__form__input--type"
            placeholder="E-Mail"
            id="email"
            v-model="email"
            name="email"
            type="email"
          />
          <p class="error" v-if="errors.email">{{ errors.email }}</p>
          <p class="error" v-if="errors.msg">{{ errors.msg }}</p>
        </div>

        <div class="modal__btns">
          <app-button
            class="btn btn--cancel"
            type="button"
            @click.native="
              $store.commit('modals/SET_SHOW_VIEW_ADMIN_MODAL', false)
            "
            >{{ $t("cancel") }}
          </app-button>
          <app-button type="submit" class="btn btn--save">
            {{ $t("save") }}
          </app-button>
        </div>
      </form>
      <confirm-dialogue ref="ConfirmDialogue" />
      <a
        class="modal__close"
        @click="$store.commit('modals/SET_SHOW_VIEW_ADMIN_MODAL', false)"
        ><img src="../../assets/icons/close.svg"
      /></a>
    </div>
  </div>
</template>

<script>
import AppButton from "../global/AppButton.vue";
import ConfirmDialogue from "../global/ConfirmDialogue.vue";
import AppInput from "../global/AppInput.vue";
import { mapActions } from "vuex";
import api from "@/api/api";
import vClickOutside from "v-click-outside";

export default {
  components: { AppButton, ConfirmDialogue, AppInput },
  name: "ViewAdminModal",
  data() {
    return {
      idAdmin: 0,
      fullName: "",
      email: "",
      errors: {
        fullName: "",
        email: "",
        msg: "",
      },
    };
  },
  props: {
    adminItem: {
      type: Object,
      required: false,
    },
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  mounted() {
    const { id, full_name, email } = this.adminItem;
    this.idAdmin = id;
    this.fullName = full_name;
    this.email = email;
  },

  methods: {
    ...mapActions({
      updateAdmin: "admins/updateAdmin",
    }),

    async update() {
      this.errors.fullName = !this.fullName ? this.$t("empty_field") : "";
      this.errors.email = !this.email ? this.$t("empty_field") : "";
      let dataToSend = this.adminItem;

      if (this.fullName && this.email) {
        if (dataToSend.full_name !== this.fullName) {
          try {
            let data = await api({ requiresAuth: true }).put(
              `users/${dataToSend.id}/update`,
              {
                full_name: this.fullName,
              }
            );

            if (data.request.status) {
              let nextId = 1;
              const notification = {
                type: "success",
                message: this.$t("success_update_user"),
                id: nextId++,
              };
              this.$store.commit(
                "notification/ADD_NOTIFICATION",
                notification,
                {
                  root: true,
                }
              );
            }
            this.$store.commit("admins/SET_UPDATE_ADMIN", data.data);
            this.$store.commit("modals/SET_SHOW_VIEW_ADMIN_MODAL", false);
          } catch (error) {
            console.log(error);
            if (error.response.data.message[0].includes("email")) {
              this.errors.email = this.$t("email_exists_choose_another");
            }
          }
        }
        if (this.email != dataToSend.email) {
          try {
            let data = await api({ requiresAuth: true }).put(
              `users/${dataToSend.id}/update`,
              {
                email: this.email,
              }
            );
            if (data.request.status) {
              let nextId = 1;
              const notification = {
                type: "success",
                message: this.$t("success_update_user"),
                id: nextId++,
              };
              this.$store.commit(
                "notification/ADD_NOTIFICATION",
                notification,
                {
                  root: true,
                }
              );
            }
            this.$store.commit("modals/SET_SHOW_VIEW_ADMIN_MODAL", false);
            this.$store.commit("admins/SET_UPDATE_ADMIN", data.data);
          } catch (error) {
            if (error.response.data.message[0].includes("email")) {
              this.errors.email = this.$t("email_taken");
            }
          }
        }
      }
    },
    onClickOutside() {
      this.$store.commit("modals/SET_SHOW_VIEW_ADMIN_MODAL", false);
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/components/global/modal-global";
</style>
