import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const messages = {
  en: {
    name: "Name",
    log_out: "Log out",
    log_in: "Log in",
    list_of_users: "List of users",
    full_name: "Full Name",
    email: "Email",
    created_at: "Created at",
    classes: "Subjects",
    list_of_activities: "List of activites",
    list_of_classes: "List of subjects",
    category: "Category",
    search_activity: "Search activity...",
    search_user: "Search users...",
    search_admin: "Search admin...",
    my_profile: "My Profile",
    admins: "Admins",
    dashboard: "Dashboard",
    users: "Users",
    children: "Children",
    subjects: "Subjects",
    charts: "Charts",
    all_children: "Children",
    list_of_children: "List of Children",
    list_of_admins: "List of admins",
    female: "Female",
    male: "Male",
    under_10: "Under 10",
    over_10: "Over 10",
    under_4: "Under 4",
    over_4: "Over 4",
    children_schedule: "Children Schedule",
    no_users_found: "No users found",
    search_children: "Search children...",
    search_subject: "Search subject...",
    no_subjects_found: "No subject found",
    no_children_found: "No children found",
    no_activity_found: "No activity found",
    no_admins_found: "No admins found",
    delete: "Delete",
    role_id: "Role",
    age: "Age",
    class: "Class",
    activities: "Activities",
    submit_edit: "Submit Edit",
    change_password: "Change Password",
    current_password: "Current Password",
    new_password: "New Password",
    confirm_new_password: "Confirm New Password",
    save: "Save",
    go_back_to_profile: "Go back to profile",
    login_to_your_account: "Login To Your Account",
    password: "Password",
    recover_password: "Recover Your Password",
    reset_your_password: "Reset Your Password",
    go_back_to: "Go back to ",
    reset_password: "Reset Password",
    view: "View",
    user_details: "User Details",
    edit_email: "Edit email",
    cancel: "Cancel",
    child_details: "Child Details",
    edit_class: "Edit Class",
    edit_age: "Edit Age",
    create_activity: "Create Activity",
    add_subject: "Add subject",
    admin_details: "Admin details",
    add_admin: "Add Admin",
    send_invite: "Send invite",
    edit_activity: "Edit activity",
    edit_activity_name: "Edit activity name",
    edit_subject: "Edit subject",
    list_of_subjects: "List of subjects",
    empty_field: "This field must be filled",
    success_update_name: "Succesfully updated name",
    success_update_email: "Succesfully updated email",
    success_deleted_user: "Successfuly deleted user.",
    success_deleted_subject: "Successfuly deleted subject.",
    success_created_user: "Succesfully created user.",
    email_taken: "That email is taken ",
    confirm_delete_user: "Are you sure you want to delete this user?",
    access_delete: "You don't have access to delete!",
    delete_user: "Delete User",
    same_child_data: "Same child data.",
    subject_exists: "This subject already exists",
    delete_subject: "Are you sure you want to delete this subject?",
    confirm_delete_activity: "Are you sure you want to delete this activity?",
    from: "From",
    to: "To ",
    female_select: "Female",
    male_select: "Male",
    remember_me: "Remember me",
    forgot_password: "Forgot Password?",
    invalid_email: "Invalid email or password",
    network_error: "Network error",
    create_account: "Create Account",
    confirm_password: "Confirm Password",
    i_accept: "I accept",
    terms_and_conditions: "Terms and Conditions",
    already_have_account: "Already have and account?",
    sign_up: "Sign up",
    all: "All",
    new_password_required: "New password required",
    short_password: "Password must be longer than 6 characters.",
    short_username: "Username should be longer than 6 characters.",
    password_not_match: "Password do not match!",
    username_taken: "That username is taken.",
    problem_creating_user: "There was a problem creating user.",
    success_update_user: "Successfuly updated user.",
    activity_exists: "Activity already exist.",
    success_update_subject: "Succesfully updated subject",
    success_update_activity: "Succesfully updated activity",
    success_created_activity: "Succesfully created activity",
    succesfully_invited: "Succesfully invited admin.",
    success_deleted_activity: "Succesfully deleted activity.",
    something_went_wrong: "Something went wrong.",
    invalid_email_msg: "Invalid email",
    same_user_data: "Same user data",
    confirm_delete_admin: "Are you sure you want to delete this admin?",
    email_exists_choose_another: "Email already exists. Choose another one.",
    password_succes_changed: "Password was succesfully changed.",
    old_new_pass_different: "Old and new password must be different.",
    current_password_incorrect: "Current password is incorrect!",
    please_enter_password: "Please enter your password",
    success_reset_password: "Succesfully reseted password.",
    enter_a_valid_email: "Please enter a valid email",
    success_sent: "Succesfuly sent request.",
    server_error: "Server Error.",
    no_data: "No data.",
    succesfully_invited_admin: "Succesfully invited admin.",
  },
  al: {
    name: "emri",
    log_out: "Dil",
    list_of_users: "Lista e përdoruesve",
    full_name: "Emri",
    email: "Imella",
    created_at: "Krijuar në",
    classes: "Klasat",
    list_of_activities: "Lista e aktiviteteve",
    list_of_classes: "List e klasave",
    category: "Kategoria",
    search_activity: "Kërko aktivitet...",
    search_user: "Kërko përdorues...",
    admins: "Administratorët",
    dashboard: "Paneli",
    users: "Perdoruesit",
    children: "Fëmijët",
    subjects: "Lëndët",
    charts: "Grafikat",
    all_children: "Fëmijët",
    female: "Femra",
    male: "Meshkuj",
    under_10: "Nën 10",
    over_10: "Mbi 10",
    under_4: "Nën 4",
    over_4: "Mbi 4",
    children_schedule: "Orari",
    list_of_children: "Lista e fëmijëve",
    list_of_admins: "Lista e administratorëve",
    no_users_found: "Nuk u shfaq asnjë përdorues",
    search_children: "Kërko fëmijë...",
    search_subject: "Kërko lëndë...",
    search_admin: "Kërko admin...",
    no_subjects_found: "Nuk u shfaq asnjë lëndë",
    no_children_found: "Nuk u shfaq asnjë fëmijë",
    no_activity_found: "Nuk u shfaq asnjë aktivitet ",
    no_admins_found: "Nuk u shfaq asnjë admin",
    delete: "Fshije",
    role_id: "Roli",
    age: "Mosha",
    class: "Klasa",
    activities: "Aktivitetet",
    my_profile: "Profili im",
    submit_edit: "Ruaj ndryshimin",
    change_password: "Ndrysho fjalëkalimin",
    current_password: "Fjalëkalimi aktual",
    new_password: "Fjalëkalim i ri",
    confirm_new_password: "Konfirmo fjalëkalimin e ri",
    save: "Ruaje",
    go_back_to_profile: "Kthehu te profili",
    login_to_your_account: "Kyçu në llogarinë tuaj",
    password: "Fjalëkalimi",
    recover_password: "Ktheni fjalëkalimin",
    go_back_to: "Kthehuni te ",
    reset_your_password: "Ndryshoni fjalëkalimin tuaj",
    reset_password: "Ndrysho fjalëkalimin",
    view: "Shiko",
    user_details: "Detajet e përdoruesit",
    admin_details: "Detajet e administratorit",
    edit_fullname: "Ndrysho emrin",
    edit_email: "Ndrysho imellën",
    cancel: "Anulo",
    child_details: "Detajet e fëmiut",
    edit_class: "Ndrysho klasën",
    edit_age: "Ndrysho Moshën",
    create_activity: "Shto aktivitet",
    add_subject: "Shto lëndë",
    add_admin: "Shto administratorin",
    send_invite: "Dërgo",
    edit_activity: "Ndrysho aktivitetin",
    edit_activity_name: "Ndrysho aktivitetin",
    edit_subject: "Ndrysho lëndën",
    list_of_subjects: "Lista e lëndëve",
    empty_field: "Kjo fushë duhet plotësuar",
    success_update_name: "Emri u ndryshua me sukses",
    success_update_email: "Imella u ndryshua me sukses",
    success_deleted_user: "Përdoruesi u fshi me sukses",
    success_deleted_subject: "Lënda u fshi me sukses",
    email_taken: "Imella ekziston ",
    confirm_delete_user: "A dëshironi të fshini këtë përdorues?",
    confirm_delete_activity: "A dëshironi të fshini këtë aktivitet?",
    access_delete: "Nuk keni çasje për të fshirë!",
    delete_user: "Fshije",
    same_child_data: "Të dhënat e njëjta",
    subject_exists: "Lënda ekziston",
    delete_subject: "A dëshironi të fshini këtë lëndë?",
    from: "Nga",
    to: "Deri",
    female_select: "Femër",
    male_select: "Mashkull",
    remember_me: "Më kujto",
    forgot_password: "Keni harruar fjalëkalimin?",
    log_in: "Kyçu",
    invalid_email: "Imella ose fjalëkalimi gabim.",
    network_error: "Problem me internet",
    create_account: "Krijo llogari",
    username: "Emri perdoruesit",
    confirm_password: "Konfirmo fjalëkalimin",
    i_accept: "I pranoj",
    terms_and_conditions: "Termat dhe kushtet",
    already_have_account: "Keni llogari të krijuar?",
    sign_up: "Regjistrohu",
    all: "Të gjithë",
    new_password_required: "Fjalëkalimi i ri kërkohet",
    short_password: "Fjalëkalimi duhet të jetë së paku 6 karaktere.",
    short_username: "Username duhet të jetë së paku 6 karaktere.",
    password_not_match: "Fjalëkalimi nuk përputhet!",
    success_created_user: "Përdoruesi u krijua me sukses.",
    username_taken: "Username ekziston.",
    problem_creating_user: "Problem gjatë krijimit të përdoruesit.",
    success_update_user: "Të dhënat u ndryshuan me sukses.",
    success_deleted_activity: "Aktiviteti u fshi me sukses.",
    activity_exists: "Aktiviteti ekziston.",
    success_update_subject: "Lënda u ndryshua me sukses.",
    success_update_activity: "Aktiviteti u ndryshua me sukses.",
    success_created_activity: "Aktiviteti u shtua me sukses.",
    succesfully_invited_admin: "Administratori u ftua me sukses.",
    something_went_wrong: "Diçka shkoi gabim.",
    invalid_email_msg: "Imella nuk është e vlefshëm.",
    same_user_data: "Të dhënat e përdoruest janë të njëjta.",
    confirm_delete_admin: "Sigurt do të fshini administratorin?",
    email_exists_choose_another: "Imella ekziston. Zgjidhni tjetër imell.",
    password_succes_changed: "Fjalëkalimi u ndryshua me sukses.",
    old_new_pass_different:
      "Fjalëkalimi nuk duhet të jetë i ngjajshëm me të vjetrin.",
    current_password_incorrect: "Fjalëkalimi aktual gabim!",
    please_enter_password: "Ju lutem shtypni fjalëkalimi.",
    success_reset_password: "Fjalëkalimi u ndryshua me sukses.",
    enter_a_valid_email: "Ju lutem shkruani një imell të vlefshëm.",
    success_sent: "Kërkesa u dërgua me sukses.",
    server_error: "Problem me server.",
    no_data: "Nuk ka të dhëna.",
  },
};

const i18n = new VueI18n({
  locale: "en",
  fallbackLocale: "al",
  messages,
});

export default i18n;
