<template>
  <div class="modal" v-click-outside="onClickOutside">
    <div class="modal__wrapper">
      <h2 class="modal__title">{{ $t("add_admin") }}</h2>
      <form class="modal__form" @submit.prevent="inviteUser">
        <div class="modal__form__input">
          <app-input
            :hasErrors="true"
            :class="{ hasError: errors.email }"
            type="email"
            name="email"
            :label="$t('email')"
            v-model="email"
            class="modal__form__input--type"
          />
          <p class="error" v-if="errors.email">{{ errors.email }}</p>
        </div>
        <div class="modal__btns">
          <app-button
            class="btn btn--cancel"
            type="button"
            @click.native="$store.commit('modals/SET_ADD_ADMIN_MODAL', false)"
            >{{ $t("cancel") }}
          </app-button>
          <app-button class="btn btn--save" type="submit">{{
            $t("send_invite")
          }}</app-button>
        </div>
      </form>
      <a
        class="modal__close"
        @click="$store.commit('modals/SET_ADD_ADMIN_MODAL', false)"
        ><img src="../../assets/icons/close.svg"
      /></a>
    </div>
  </div>
</template>

<script>
import AppInput from "@/components/global/AppInput.vue";
import AppButton from "@/components/global/AppButton.vue";
import { mapActions } from "vuex";
import vClickOutside from "v-click-outside";

export default {
  components: { AppButton, AppInput },
  name: "AddAdminModal",

  data() {
    return {
      fullName: null,
      email: "",
      isLoading: false,
      errors: {
        fullName: "",
        email: "",
      },
    };
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  watch: {
    fullName() {
      if (this.fullName) {
        this.errors.fullName = "";
      }
    },
  },
  methods: {
    ...mapActions({
      inviteAdmin: "superadmin/inviteAdmin",
    }),

    async inviteUser() {
      const data = {
        email: this.email,
      };
      try {
        await this.inviteAdmin(data);
        this.$store.commit("modals/SET_ADD_ADMIN_MODAL", false);
        let nextId = 1;
        const notification = {
          type: "success",
          message: this.$t("succesfully_invited_admin"),
          id: nextId++,
        };

        this.$store.commit("notification/ADD_NOTIFICATION", notification, {
          root: true,
        });
      } catch (err) {
        if (err.response.data.message.includes("email should not be empty")) {
          this.errors.email = this.$t("empty_field");
        } else {
          this.errors.email = "";
        }
      }
    },
    onClickOutside() {
      this.$store.commit("modals/SET_ADD_ADMIN_MODAL", false);
    },
  },
  validateEmail(email) {
    //eslint-disable-next-line
    const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(email)) {
      this.errors.email = "";
    } else {
      this.errors.email = this.$t("invalid_email_msg");
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/global/modal-global";
</style>
