import api from "@/api/api";

export default {
  namespaced: true,
  state: {
    children: [],
    loading: false,
    totalChildren: 0,
    childrenStats: [],
  },
  getters: {
    children: (state) => state.children,
    loadingChildren: (state) => state.loading,
    totalChildren: (state) => state.totalChildren,
    getFilteredChildren: (state) => state.totalChildren,
    getChildrenStats: (state) => state.childrenStats,
  },
  mutations: {
    SET_ALL_CHILDREN: (state, data) => {
      state.children = data;
    },

    UPDATE_CHILD(state, childData) {
      let childIndex = state.children.findIndex(
        (child) => child.id === childData.id
      );
      if (childIndex !== -1) {
        const temp = [...state.children];
        temp[childIndex] = childData;
        state.children = temp;
      }
    },
    SET_TOTAL_CHILDREN: (state, data) => {
      state.totalChildren = data;
    },
    SET_CHILDREN_STATS: (state, data) => {
      state.childrenStats = data;
    },
  },
  actions: {
    async getAllChildren({ commit }, { full_name, page, perPage }) {
      const params = {};
      if (full_name) {
        params.full_name = full_name;
      }
      if (page) {
        params.page = page;
      }
      if (perPage) {
        params.perPage = perPage;
      }

      try {
        this.state.loading = true;
        let { data } = await api({ requiresAuth: true }).get(`/child`, {
          params,
        });
        commit("SET_ALL_CHILDREN", data.child);
        commit("SET_TOTAL_CHILDREN", data.total);
        this.state.loading = false;
      } catch (err) {
        console.error("Something went wrong.Can't get children " + err);
      }
    },
    async childrenStats({ commit }, params) {
      let { data } = await api({ requiresAuth: true }).get(
        `/child/statistic/chart`,
        { params }
      );
      commit("SET_CHILDREN_STATS", data);
      // store.commit("modals/SET_SHOW_FILTER_POPUP", false);
    },

    async updateChild({ commit }, dataToSend) {
      let { data } = await api({ requiresAuth: true }).put(
        `/child/${dataToSend.id}/update`,
        dataToSend
      );
      commit("UPDATE_CHILD", data);
    },
    async updateChildWithApi(state, data) {
      return await api({ requiresAuth: true }).put(
        `/child/${data.id}/update`,
        data
      );
    },
  },
};

// const params = {};
// age, age_from, age_to, class_from, class_to, grade, gender
// if (age) {
//   params.age = age;
// }
// if (age_from) {
//   params.age_from = age_from;
// }
// if (age_to) {
//   params.age_to = age_to;
// }
// if (class_from) {
//   params.class_from = class_from;
// }
// if (class_to) {
//   params.class_to = class_to;
// }
// if (grade) {
//   params.grade = grade;
// }
// if (gender) {
//   params.gender = gender;
// }
