<template>
  <main class="starter">
    <div class="starter__wrapper">
      <div class="starter__wrapper__image">
        <img
          class="starter__wrapper__image--bg"
          src="../assets/images/pattern.png"
          alt="Login Picture"
        />
      </div>
      <div class="login__wrapper">
        <div class="login">
          <h2 class="login__title">{{ $t("login_to_your_account") }}</h2>
          <form class="login__form" @submit.prevent="login()">
            <p class="login__email">LOGIN WITH EMAIL</p>
            <div class="login__form__input">
              <app-input
                type="text"
                label="Email"
                id="loginUsername"
                class="login-username"
                name="email"
                v-model="email"
                :class="{ hasError: !errors }"
                :hasErrors="errors.email ? true : true"
              ></app-input>
              <p class="error">{{ errors.email }}</p>
            </div>
            <div class="login__form__input">
              <app-input
                :type="showPassword ? 'text' : 'password'"
                :label="$t('password')"
                id="loginPassword"
                class="login-password"
                name="password"
                password
                :hasErrors="errors.password ? true : true"
                @password-toggle="showPassword = !showPassword"
                :showPassword="showPassword"
                v-model="password"
              >
                ></app-input
              >
              <p class="error">{{ errors.password }}</p>
            </div>
            <div class="login__form__crd">
              <label for="rememberMe">
                <input
                  type="checkbox"
                  name="rememberMe"
                  id="rememberMe"
                  v-model="rememberMe"
                /><span class="login__form__crd--remember-me">
                  {{ $t("remember_me") }}
                </span>
              </label>
              <router-link
                class="login__form__crd--forgot-password"
                to="/auth/forgetPassword"
                >{{ $t("forgot_password") }}</router-link
              >
            </div>
            <p class="error">{{ errors.generalMsg }}</p>
            <app-button
              class="btn btn--login"
              type="submit"
              :isLoading="isLoading"
              :class="isLoading ? 'wait' : ''"
              >{{ $t("log_in") }}</app-button
            >
          </form>
          <FlagButtonVue />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import AppButton from "@/components/global/AppButton.vue";
import AppInput from "@/components/global/AppInput.vue";
import FlagButtonVue from "@/components/global/FlagButton.vue";
export default {
  name: "AppLogin",
  components: {
    AppButton,
    AppInput,
    FlagButtonVue,
  },
  data() {
    return {
      hasError: false,
      isLoading: false,
      password: "",
      email: "",
      showPassword: false,
      showModal: false,
      errors: {
        email: "",
        password: "",
        generalMsg: "",
      },
      rememberMe: false,
    };
  },
  created() {
    if (this.getCookie("myEmail") && this.getCookie("myPassword")) {
      this.getCookieData();
      this.rememberMe = true;
    }
  },
  methods: {
    getCookie(cname) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    getCookieData() {
      let email = this.getCookie("myEmail");
      let pswd = this.getCookie("myPassword");

      this.email = email;
      this.password = pswd;
    },
    async login() {
      this.errors.email = !this.email ? this.$t("empty_field") : "";
      this.errors.password = !this.password ? this.$t("empty_field") : "";
      this.isLoading = true;

      if (this.email && this.password) {
        this.errors.email = "";
        this.errors.password = "";
        try {
          const {
            data: { user, access_token },
          } = await this.$store.dispatch("login", {
            email: this.email,
            password: this.password,
          });

          this.$store.commit("SET_USER_DATA", user);
          localStorage.setItem("access_token", access_token);
          localStorage.setItem("user", JSON.stringify(user));
          this.$router.push({ name: "DoughnutChart" });
        } catch (err) {
          this.isLoading = false;
          let nextId = 1;
          const notification = {
            type: "error",
            message: this.$t("something_went_wrong"),
            id: nextId++,
          };
          this.$store.commit("notification/ADD_NOTIFICATION", notification, {
            root: true,
          });
          if (err.response.status == 500) {
            this.errors.generalMsg = this.$t("network_error");
          } else {
            this.errors.generalMsg = this.$t("invalid_email");
          }
        }
        this.isLoading = false;
      }
      this.isLoading = false;
    },
  },
  watch: {
    isLoading(val) {
      return (this.isLoading = val);
    },
    rememberMe(value) {
      if (value) {
        document.cookie =
          "myEmail=" + this.email + `;${process.env.VUE_APP_BASE_URL}`;
        document.cookie =
          "myPassword=" + this.password + `;${process.env.VUE_APP_BASE_URL}`;
      } else {
        document.cookie = "myEmail=";
        document.cookie = "myPassword=";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/pages/app-login";
</style>
