<template>
  <div id="app">
    <transition name="fade" mode="out-in">
      <router-view @toggle="toggle($event)"> </router-view>
    </transition>
    <notification-container />
  </div>
</template>
<script>
import NotificationContainer from "@/components/global/NotificationContainer.vue";
import { mapActions } from "vuex";

export default {
  name: "App",
  components: {
    NotificationContainer,
  },
  data() {
    return {
      mode: "light",
    };
  },
  created() {
    if (localStorage.getItem("darkMode") === "true") {
      document.body.classList = "darkMode";
    }
    if (
      localStorage.getItem("rememberMe") === "true" &&
      this.$route.name !== "DoughnutChart"
    ) {
      this.$router.push({ name: "DoughnutChart" });
    }
    let getCurrentLang = localStorage.getItem("lang");
    if (getCurrentLang) {
      this.$i18n.locale = getCurrentLang;
    }
  },

  methods: {
    toggle({ target }) {
      if (target.checked) {
        this.mode = "dark";
        localStorage.setItem("darkMode", true);
        document.body.classList = "darkMode";
      } else {
        localStorage.setItem("darkMode", false);
        this.mode = "light";
        document.body.classList = "";
      }
    },

    ...mapActions({
      setNightMode: "switcher/setNightMode",
    }),
  },
};
</script>
<style scoped>
.fade-enter,
.fade-leave-to {
  opacity: 0;

  transform: translate(1rem);
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease;
}
</style>
