import api from "@/api/api";
export default {
  namespaced: true,
  state: {
    activities: [],
    filteredActivities: [],
    totalActivities: 0,
  },
  getters: {
    activities: (state) => state.activities,
    totalActivities: (state) => state.totalActivities,
  },
  mutations: {
    SET_ALL_ACTIVITIES: (state, data) => {
      state.activities = data;
    },
    SET_TOTAL_ACTIVITIES: (state, data) => {
      state.totalActivities = data;
    },
    UPDATE_ACTIVITY(state, activityData) {
      let findActivity = state.activities.find(
        (activity) => activity.id == activityData.id
      );
      findActivity.name = activityData.name;
    },
    CREATE_ACTIVITY(state, data) {
      state.activities.unshift(data);
    },
  },
  actions: {
    async getActivities({ commit }, { search, page, perPage }) {
      const params = {};
      if (search) {
        params.search = search;
      }
      if (page) {
        params.page = page;
      }
      if (perPage) {
        params.perPage = perPage;
      }
      try {
        let { data } = await api({ requiresAuth: true }).get(`/activity`, {
          params,
        });

        commit("SET_ALL_ACTIVITIES", data.items);
        commit("SET_TOTAL_ACTIVITIES", data.total);
      } catch (err) {
        console.error("Something went wrong" + err);
      }
    },
    async updateActivity({ commit }, data) {
      commit("UPDATE_ACTIVITY", data);
    },
    async createActivity({ commit }, data) {
      commit("CREATE_ACTIVITY", data);
    },
  },
};
