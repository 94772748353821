<template>
  <popup-modal ref="popup">
    <p class="popup__message">{{ message }}</p>
    <div class="popup__btns">
      <app-button type="button" class="btn btn--cancel" @click.native="_cancel">
        {{ cancelButton }}
      </app-button>
      <app-button
        type="button"
        class="btn btn--delete"
        :class="hasAcces ? '' : 'disabledBtn'"
        @click.native="_confirm"
        >{{ okButton }}</app-button
      >
    </div>
  </popup-modal>
</template>

<script>
import PopupModal from "./PopupModal.vue";
import AppButton from "./AppButton.vue";
export default {
  name: "ConfirmDialogue",
  components: { PopupModal, AppButton },

  data: () => ({
    title: "",
    message: "",
    okButton: "",
    cancelButton: "",
    hasAcces: true,
    resolvePromise: "",
    rejectPromise: "",
    getLang: "",
  }),
  mounted() {
    this.hasAcces = this.$store.getters.isSuperAdmin;
    window.addEventListener("lang", (event) => {
      this.getLang = event.detail.storage;
    });
    this.cancelButton = this.$t("cancel");
  },

  methods: {
    show(opts = {}) {
      this.title = opts.title;
      this.message = opts.message;
      this.okButton = opts.okButton;
      this.cancelButton = opts.cancelButton;
      if (opts.cancelButton) {
        this.cancelButton = opts.cancelButton;
      }

      this.$refs.popup.open();

      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },

    _confirm() {
      this.$refs.popup.close();
      this.resolvePromise(true);
    },

    _cancel() {
      this.$refs.popup.close();
      this.resolvePromise(false);
    },
  },
};
</script>

<style lang="scss" scoped>
.popup__btns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: toRem(20);
}

.popup__message {
  padding: toRem(20);
  color: $title-color;
}
</style>
