export default {
  namespaced: true,
  state: {
    notifications: [],
    nextId: 0,
  },
  getters: {},
  mutations: {
    ADD_NOTIFICATION(state, notification) {
      let next = (state.nextId += 1);

      state.notifications.push({
        ...notification,
        id: next,
      });
    },
    REMOVE_NOTIFICATION(state, notificationToRemove) {
      state.notifications = state.notifications.filter(
        (notification) => notification.id !== notificationToRemove.id
      );
    },
  },

  actions: {
    addNotification({ commit }, notification) {
      commit("ADD_NOTIFICATION"), notification;
    },
    removeNotification({ commit }, notificationToRemove) {
      commit("REMOVE_NOTIFICATION", notificationToRemove);
    },
  },
};
