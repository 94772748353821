import Vue from "vue";
import VueRouter from "vue-router";
import AppLogin from "@/views/AppLogin";
import LandingPage from "@/views/LandingPage";
import UsersList from "@/views/UsersList";
import AppSignUp from "@/views/AppSignUp";
import UserClasses from "@/views/UserClasses";
import ActivitiesList from "@/views/ActivitiesList";
import NProgress from "nprogress";
import RecoverPassword from "../views/RecoverPassword";
import ResetPassword from "../views/ResetPassword";
import SuperAdminProfile from "@/views/SuperAdminProfile";
import AdminsList from "@/views/AdminsList";
import ChangePassword from "@/views/ChangePassword";
import DoughnutChart from "@/views/DoughnutChart";
import ChildrenList from "@/views/ChildrenList";
import LockUser from "@/views/LockUser";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: "/Charts",
  },
  { path: "/login", name: "AppLogin", component: AppLogin },
  { path: "/lockscreen", name: "LockUser", component: LockUser },

  {
    path: "/Charts",
    name: "LandingPage",
    component: LandingPage,
    meta: { requiresAuth: true },
    children: [
      {
        path: "/Charts",
        name: "DoughnutChart",
        component: DoughnutChart,
      },
      {
        path: "/users",
        name: "UsersList",
        component: UsersList,
        params: true,
      },
      {
        path: "/usersclasses",
        name: "UserClasses",
        component: UserClasses,
      },
      {
        path: "/activities",
        name: "ActivitiesList",
        component: ActivitiesList,
      },
      {
        path: "/superadmin",
        name: "SuperAdminProfile",
        component: SuperAdminProfile,
      },
      {
        path: "/adminslist",
        name: "AdminsList",
        component: AdminsList,
      },
      {
        path: "/change-password",
        name: "ChangePassword",
        component: ChangePassword,
      },
      {
        path: "/children",
        name: "ChildrenList",
        component: ChildrenList,
      },
    ],
  },

  { path: "/auth/register/:token", name: "SignUp", component: AppSignUp },
  {
    path: "/auth/forgetPassword",
    name: "RecoverPassword",
    component: RecoverPassword,
  },
  {
    path: "/auth/resetPassword/:token",
    name: "ResetPassword",
    component: ResetPassword,
    params: true,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("access_token");
  NProgress.inc();

  if (to.matched.some((record) => record.meta.requiresAuth) && !loggedIn) {
    NProgress.inc();
    next({ name: "AppLogin" });
    window.location.replace("login");
  } else if (
    to.name === "AppLogin" &&
    localStorage.getItem("rememberMe") === "true"
  ) {
    next();
  } else if ((to.name === "AppLogin" || to.name === "SignUp") && loggedIn) {
    next({ name: "LandingPage" });
  }

  next();
});
router.afterEach(() => {
  NProgress.done();
});

export default router;
