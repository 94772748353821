export default {
  namespaced: true,
  state: {
    nightMode: "light",
  },
  getters: {
    getCurrentMode: (state) => state.nightMode,
  },
  mutations: {
    SET_NIGHT_MODE(state, data) {
      state.nightMode = data;
    },
  },
  actions: {
    setNightMode({ commit }, data) {
      commit("SET_NIGHT_MODE", data);
    },
  },
};
