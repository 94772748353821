<template>
  <div class="change-password">
    <div class="change-password__wrapper">
      <div class="change-password__content">
        <h2 class="change-password-title">
          {{ $t("change_password").toUpperCase() }}
        </h2>
        <form
          class="change-password__content__form"
          @submit.prevent="submitPassword()"
        >
          <app-input
            :type="showCurrrentPassword ? 'text' : 'password'"
            :label="$t('current_password')"
            id="currentPassword"
            name="password"
            password
            v-model="currentPassword"
            @password-toggle="showCurrrentPassword = !showCurrrentPassword"
            :showPassword="showCurrrentPassword"
            :class="{ hasError: errors.currentPassword }"
            :hasErrors="!!errors.currentPassword"
          ></app-input>
          <p class="error">
            {{ errors.currentPassword }}
          </p>
          <app-input
            :type="showPassword ? 'text' : 'password'"
            :label="$t('new_password')"
            id="newPassword"
            name="password"
            password
            @password-toggle="showPassword = !showPassword"
            :showPassword="showPassword"
            v-model="password"
            :class="{ hasError: errors.password }"
            :hasErrors="!!errors.password"
          ></app-input>
          <p class="error">
            {{ errors.password }}
          </p>

          <app-input
            :type="showConfirmPassword ? 'text' : 'password'"
            :label="$t('confirm_new_password')"
            id="confirmPassword"
            name="confirmPassword"
            password
            @password-toggle="showConfirmPassword = !showConfirmPassword"
            :showPassword="showConfirmPassword"
            v-model="confirmPassword"
            :class="{ hasError: errors.confirmPassword }"
            :hasErrors="!!errors.confirmPassword"
          ></app-input>
          <p class="error">
            {{ errors.confirmPassword }}
          </p>
          <app-button class="btn btn--save" type="submit">{{
            $t("save")
          }}</app-button>
          <app-button
            @click.native="$router.push('/superadmin')"
            class="btn btn--save"
            type="button"
            >{{ $t("go_back_to_profile") }}</app-button
          >
          <p class="text-success">{{ generalMsg }}</p>
          <p class="text-error">{{ errorMsg }}</p>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import AppInput from "@/components/global/AppInput.vue";
import AppButton from "@/components/global/AppButton.vue";
import { mapActions } from "vuex";
export default {
  name: "ChangePassword",
  components: {
    AppInput,
    AppButton,
  },
  data() {
    return {
      inputError: false,
      currentPassword: "",
      password: "",
      confirmPassword: "",
      showCurrrentPassword: false,
      showPassword: false,
      showConfirmPassword: false,
      generalMsg: "",
      errorMsg: "",
      errors: {
        currentPassword: "",
        confirmPassword: "",
        password: "",
      },
    };
  },

  methods: {
    ...mapActions(["changePassword"]),
    async submitPassword() {
      if (!this.currentPassword) {
        this.errors.currentPassword = this.$t("change_password");
      } else {
        this.errors.currentPassword = "";
      }
      if (!this.password) {
        this.errors.password = this.$t("new_password_required");
      } else {
        this.errors.password = "";
      }
      if (!this.confirmPassword) {
        this.errors.confirmPassword = this.$t("confirm_new_password");
      } else {
        this.errors.confirmPassword = "";
      }
      this.currentPassword.length < 6 && this.currentPassword
        ? (this.errors.currentPassword = this.$t("short_password"))
        : "";
      if (this.currentPassword && this.password && this.confirmPassword) {
        try {
          const data = {
            current_password: this.currentPassword,
            new_password: this.password,
            password_confirm: this.confirmPassword,
          };
          const res = await this.changePassword(data);
          if (res.status == 200) {
            this.generalMsg = this.$t("password_succes_changed");
          } else {
            this.generalMsg = "";
          }
        } catch (err) {
          if (err.response.status == 400) {
            console.error("Something went wrong");
          }
          if (err.response.data.error.includes("incorrect"))
            this.errors.currentPassword = this.$t("current_password_incorrect");
          if (err.response.data.error.includes("same"))
            this.errors.password = this.$t("old_new_pass_different");
          let msg = err.response.data.message[0];
          if (msg.includes("match"))
            this.errors.confirmPassword = this.$t("password_not_match");
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/pages/change-password.scss";
</style>
