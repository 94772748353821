<template>
  <div class="page">
    <div class="test">
      <h2 class="page__title">{{ $t("list_of_children") }}</h2>
    </div>
    <div class="page__search">
      <app-input
        class="page__input__search input--search"
        type="search"
        name="search"
        :placeholder="$t('search_children')"
        :hasErrors="true"
        v-model="search_children"
      />
      <app-button
        @click.native="
          $store.commit('modals/SET_SHOW_FILTER_POPUP', !showFilterPopup)
        "
        type="button"
        class="btn btn--filter"
        ><font-awesome-icon icon="fa-solid fa-sliders"
      /></app-button>
      <transition name="fade">
        <filter-pop-up v-if="showFilterPopup"></filter-pop-up>
      </transition>
    </div>

    <p v-if="children.length === 0" class="page__table__title">
      {{ $t("no_children_found") }}
    </p>
    <app-table
      :columns="tableColumns"
      :table-data="children"
      :loading="loading"
      :change-page="pageNumberChange"
      :change-size-page="pageSizeChange"
      :total="totalChildren"
      :page="page"
      :perPage="perPage"
    >
    </app-table>

    <transition name="fade">
      <div class="page__modal page__modal__view" v-if="showChildDetailsModal">
        <child-modal :childItem="childItem" />
      </div>
    </transition>

    <confirm-dialogue ref="ConfirmDialogue" />
  </div>
</template>

<script>
import AppTable from "@/components/global/AppTable.vue";
import ConfirmDialogue from "@/components/global/ConfirmDialogue.vue";
import ChildModal from "@/components/Modal/ChildModal.vue";
import FilterPopUp from "@/components/Modal/FilterPopUp.vue";

import AppInput from "@/components/global/AppInput.vue";
import AppButton from "@/components/global/AppButton.vue";

import { mapGetters, mapActions } from "vuex";
import api from "@/api/api";

export default {
  name: "ChildrenList",
  components: {
    AppTable,
    ConfirmDialogue,
    AppInput,
    ChildModal,
    AppButton,
    FilterPopUp,
  },
  data() {
    return {
      childItem: {},
      viewClass: false,
      showUser: false,
      addClass: false,
      search_children: "",
      page: 1,
      perPage: 10,
      loading: false,
    };
  },

  watch: {
    async search_children(full_name) {
      if (full_name !== this.$route.query.full_name) {
        this.$router.replace({
          query: {
            full_name,
            page: 1,
            perPage: this.$route.query.perPage,
          },
        });
      }
      if (!full_name) {
        this.$router.replace({
          query: {
            page: this.$route.query.page,
            perPage: this.$route.query.perPage,
          },
        });
      } else {
        await this.fetchChildren();
      }
      if (this.search_children.length > 2) {
        try {
          await this.fetchChildren();
        } catch (err) {
          console.err("Something went wrong " + err);
        }
      } else {
        await this.fetchChildren();
      }
    },
    async page(page) {
      if (page !== +this.$route.query.page) {
        this.$router.replace({
          query: {
            full_name: this.$route.query.full_name,
            page,
            perPage: this.$route.query.perPage,
          },
        });
      }
      await this.fetchChildren();
    },
    async perPage(perPage) {
      if (perPage !== +this.$route.query.perPage) {
        this.$router.replace({
          query: {
            page: 1,
            perPage,
          },
        });
      }
      await this.fetchChildren();
    },
  },
  async mounted() {
    this.hasAcces = this.$store.getters.isSuperAdmin;
    this.loading = true;
    if (this.$route.query.full_name) {
      this.search_children = this.$route.query.full_name;
    }
    if (this.$route.query.page) {
      this.page = +this.$route.query.page;
    }
    if (this.$route.query.perPage) {
      this.perPage = +this.$route.query.perPage;
    }
    await this.fetchChildren();
    this.loading = false;
  },
  methods: {
    ...mapActions({
      getAllChildren: "children/getAllChildren",
      deleteChild: "children/deleteChild",
    }),

    async doDelete(id) {
      const ok = await this.$refs.ConfirmDialogue.show({
        title: "Delete Page",
        message: this.hasAcces
          ? this.$t("confirm_delete_user")
          : this.$t("access_delete"),
        okButton: this.$t("delete_user"),
        cancelButton: this.$t("cancel"),
      });
      if (ok) {
        await api({ requiresAuth: true }).delete(`/child/${id}/delete`);

        let nextId = 1;
        const notification = {
          type: "success",
          message: this.$t("success_deleted_user"),
          id: nextId++,
        };
        this.$store.commit("notification/ADD_NOTIFICATION", notification, {
          root: true,
        });
        await this.fetchChildren();
        this.loading = false;
      }
    },
    pageNumberChange(page) {
      this.page = page;
    },
    pageSizeChange(perPage) {
      this.perPage = perPage;
    },
    async fetchChildren() {
      await this.getAllChildren({
        full_name: this.$route.query.full_name || this.search_activity,
        page: this.$route.query.page || this.page,
        perPage: this.$route.query.perPage || this.perPage,
      });
    },
  },
  computed: {
    ...mapGetters({
      children: "children/children",
      showChildDetailsModal: "modals/showChildDetailsModal",
      totalChildren: "children/totalChildren",
      showFilterPopup: "modals/showFilterPopup",
    }),
    tableColumns() {
      return [
        {
          field: "full_name",
          key: "full_name",
          title: this.$t("full_name"),
          align: "left",
          width: "30%",
        },
        {
          field: "age",
          key: "age",
          title: this.$t("age"),
          align: "left",
          width: "20%",
        },
        {
          field: "class",
          key: "class",
          title: this.$t("class"),
          align: "left",
          width: "30%",
        },
        {
          field: "",
          key: "g",
          title: "",
          align: "right",
          width: "10%",

          renderBodyCell: ({ row }) => {
            return (
              <span>
                <button
                  class={this.hasAcces ? "btn btn--view" : "hideButton"}
                  on-click={() => {
                    this.childItem = row;
                    this.$store.commit(
                      "modals/SET_SHOW_CHILD_DETAILS_MODAL",
                      true
                    );
                  }}
                >
                  {this.$t("view")}
                </button>
              </span>
            );
          },
        },

        {
          field: "",
          key: "d",
          title: "",
          align: "right",
          width: "10%",

          renderBodyCell: (row) => {
            return (
              <span>
                <button
                  class={this.hasAcces ? "btn btn--delete-trash" : "hideButton"}
                  on-click={() => {
                    this.doDelete(row.row.id);
                  }}
                >
                  {" "}
                  <font-awesome-icon icon="fa-solid fa-trash-can" />
                </button>
              </span>
            );
          },
        },
      ];
    },
  },
};
</script>

<style lang="scss">
@import "../assets/scss/components/global/page-global";
</style>
