<template>
  <div class="reset__password">
    <div class="reset__wrapper">
      <img class="reset__wrapper-img" src="../assets/icons/Lock.svg" alt="" />
      <h2 class="reset__wrapper-title">{{ $t("reset_your_password") }}</h2>
      <form class="reset__password-form" @submit.prevent="submitReset">
        <app-input
          :type="showPassword ? 'text' : 'password'"
          :label="$t('new_password')"
          id="loginPassword"
          class="login-password reset__wrapper-input"
          name="password"
          :hasErrors="true"
          v-model="password"
          password
          @password-toggle="showPassword = !showPassword"
          :showPassword="showPassword"
        >
          ></app-input
        >
        <p class="error" v-if="errors">{{ errors.password }}</p>
        <app-input
          :type="showConfirmPassword ? 'text' : 'password'"
          :label="$t('confirm_new_password')"
          id="signupConfirmPassword"
          class="login-password reset__wrapper-input"
          name="confirmPassword"
          v-model="confirmPassword"
          password
          @password-toggle="showConfirmPassword = !showConfirmPassword"
          :showPassword="showConfirmPassword"
          :hasErrors="true"
        ></app-input>
        <p class="error" v-if="errors">{{ errors.confirmPassword }}</p>
        <p class="error" v-if="errors">{{ errors.generalMsg }}</p>

        <app-button class="btn--save" type="submit">{{
          $t("reset_password")
        }}</app-button>
      </form>

      <p class="reset__wrapper-back">
        {{ $t("go_back_to")
        }}<router-link :to="{ name: 'AppLogin' }">Login</router-link>
      </p>
    </div>
    <FlagButtonVue />
  </div>
</template>

<script>
import AppInput from "@/components/global/AppInput.vue";
import AppButton from "@/components/global/AppButton.vue";
import FlagButtonVue from "@/components/global/FlagButton.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ResetPassword",
  data() {
    return {
      showPassword: false,
      showConfirmPassword: false,
      password: "",
      confirmPassword: "",
      errors: {
        password: "",
        confirmPassword: "",
        generalMsg: "",
      },
    };
  },
  mounted() {},
  components: {
    AppInput,
    AppButton,
    FlagButtonVue,
  },
  computed: {
    ...mapGetters({
      getAllUsers: "users/getAllUsers",
    }),
  },
  methods: {
    ...mapActions(["resetPassword"]),
    async submitReset() {
      if (this.password != this.confirmPassword) {
        this.errors.generalMsg = this.$t("password_not_match");
      } else {
        this.errors.password = "";
        this.errors.confirmPassword = "";
      }
      if (!this.password) {
        this.errors.password = this.$t("please_enter_password");
      } else {
        this.errors.password = "";
      }
      if (!this.confirmPassword) {
        this.errors.confirmPassword = this.$t("please_enter_password");
      } else {
        this.errors.confirmPassword = "";
      }
      if (this.confirmPassword && this.password) {
        try {
          const data = {
            password: this.password,
            password_confirm: this.confirmPassword,
            token: this.$route.params.token,
          };
          await this.resetPassword(data);
          let nextId = 1;
          const notification = {
            type: "success",
            message: this.$t("success_reset_password"),
            id: nextId++,
          };
          this.$store.commit("notification/ADD_NOTIFICATION", notification, {
            root: true,
          });
        } catch (err) {
          if (err.response.status === 401) {
            this.errors.generalMsg = this.$t("something_went_wrong");
          } else {
            this.errors.generalMsg = "";
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/pages/reset-password";
</style>
