<template>
  <button :type="type" :disabled="isLoading">
    <slot v-if="!isLoading"></slot>
    <div v-else class="btn__loading">Loading...</div>
  </button>
</template>

<script>
export default {
  name: "AppButton",
  props: {
    type: {
      type: String,
      required: true,
      default: "button",
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
