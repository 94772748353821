<template>
  <div class="toggle-wrapper">
    <label class="toggle">
      <input
        type="checkbox"
        :checked="mode"
        @change="$emit('toggle', $event)"
      />
      <span class="toggler round"></span>
    </label>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mode: false,
    };
  },
  mounted() {
    if (localStorage.getItem("darkMode") === "true") {
      this.mode = true;
    }
  },
};
</script>

<style>
.toggle-wrapper {
  z-index: 9999;
}

.toggle {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 33px;
}
.toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggler {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #15202b;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.toggler:before {
  position: absolute;
  content: "";
  height: 21px;
  width: 21px;
  left: 7px;
  bottom: 6px;
  background: #fff;
  transition: 0.4s;
  -webkit-transition: 0.4s;
}

input:checked + .toggler {
  background: #4a61dc;
}
input:focus + toggler {
  box-shadow: 0 0 2px #4a61dc;
}

input:checked + .toggler:before {
  -webkit-transform: translateX(24px);
  -ms-transform: translateX(24px);
  transform: translateX(12px);
  border-radius: 50%;
  position: absolute;
  content: "";
  height: 21px;
  width: 21px;
  left: 7px;
  bottom: 6px;
  background: #fff;
  transition: 0.4s;
}

.toggler.round {
  border-radius: 34px;
}
.toggler.round:before {
  border-radius: 50%;
}
</style>
