<template>
  <div class="page">
    <h2 class="page__title">{{ $t("list_of_activities") }}</h2>
    <div class="page__search">
      <app-input
        type="search"
        name="search"
        :placeholder="$t('search_activity')"
        :hasErrors="true"
        v-model="search_activity"
        ref="search_activity"
        class="page__input__search input--search"
      />
      <app-button
        :class="this.hasAcces ? 'btn btn--add' : 'hideButton'"
        type="button"
        @click.native="$store.commit('modals/SET_ADD_ACTIVITY_MODAL', true)"
        ><font-awesome-icon icon="fa-solid fa-plus"
      /></app-button>
    </div>
    <p v-if="activities.length === 0" class="page__table__title">
      {{ $t("no_activity_found") }}
    </p>
    <app-table
      class="page-table"
      :columns="tableColumns"
      :table-data="activities"
      :loading="loading"
      :change-page="pageNumberChange"
      :change-size-page="pageSizeChange"
      :total="totalActivities"
      :page="page"
      :perPage="perPage"
    />

    <confirm-dialogue ref="ConfirmDialogue"></confirm-dialogue>
    <transition name="fade">
      <div class="page__modal page__modal__view" v-if="showActivityModal">
        <show-activity-modal :activityItem="activityItem"></show-activity-modal>
      </div>
      <div class="page__modal page__modal__add" v-if="addActivityModal">
        <add-activity-modal></add-activity-modal>
      </div>
    </transition>
  </div>
</template>
<script>
import AppInput from "@/components/global/AppInput.vue";
import AppTable from "@/components/global/AppTable.vue";
import AppButton from "@/components/global/AppButton.vue";
import ConfirmDialogue from "@/components/global/ConfirmDialogue.vue";
import ShowActivityModal from "@/components/Modal/ShowActivityModal.vue";
import AddActivityModal from "@/components/Modal/AddActivityModal.vue";
import { mapGetters, mapActions } from "vuex";
import api from "@/api/api";

export default {
  name: "ActivitiesList",
  components: {
    AppInput,
    AppTable,
    ConfirmDialogue,
    ShowActivityModal,
    AddActivityModal,
    AppButton,
  },
  data() {
    return {
      loading: false,
      activityItem: {},
      showModal: false,
      showActivity: false,
      search_activity: "",
      page: 1,
      perPage: 10,
      hasAcces: true,
      value: "",
      columns: [
        {
          field: "name",
          key: "a",
          title: "Activity",
          align: "left",
          width: "80%",
        },
        {
          field: "",
          key: "b",
          title: "",
          align: "left",
          width: "10%",
          renderBodyCell: ({ row }) => {
            return (
              <span>
                <button
                  class={this.hasAcces ? "btn btn--view" : "hideButton"}
                  on-click={() => {
                    this.activityItem = row;
                    this.$store.commit("modals/SET_SHOW_ACTIVITY_MODAL", true);
                  }}
                >
                  View
                </button>
              </span>
            );
          },
        },
        {
          field: "",
          key: "c",
          title: "",
          align: "right",
          width: "10%",
          renderBodyCell: ({ row }) => {
            return (
              <span>
                <button
                  class={this.hasAcces ? "btn btn--delete-trash" : "hideButton"}
                  on-click={() => {
                    this.doDelete(row.id);
                  }}
                >
                  {" "}
                  <font-awesome-icon icon="fa-solid fa-trash-can" />
                </button>
              </span>
            );
          },
        },
      ],
    };
  },
  watch: {
    async search_activity(search) {
      if (search !== this.$route.query.search) {
        this.$router.replace({
          query: {
            search,
            page: 1,
            perPage: this.$route.query.perPage,
          },
        });
      }
      if (!search) {
        this.$router.replace({
          query: {
            page: this.$route.query.page,
            perPage: this.$route.query.perPage,
          },
        });
      } else {
        await this.fetchAcitivities();
      }
      if (this.search_activity.length > 2) {
        try {
          await this.fetchAcitivities();
        } catch (err) {
          console.error("Something went wrong " + err);
        }
      } else {
        await this.fetchAcitivities();
      }
    },
    async page(page) {
      if (page !== +this.$route.query.page) {
        this.$router.replace({
          query: {
            search: this.$route.query.search,
            page,
            perPage: this.$route.query.perPage,
          },
        });
      }
      await this.fetchAcitivities();
    },
    async perPage(perPage) {
      if (perPage !== +this.$route.query.perPage) {
        this.$router.replace({
          query: {
            page: 1,
            perPage,
          },
        });
      }
      await this.fetchAcitivities();
    },
  },
  async mounted() {
    this.hasAcces = this.$store.getters.isSuperAdmin;
    this.loading = true;
    if (this.$route.query.search) {
      this.search_activity = this.$route.query.search;
    }
    if (this.$route.query.page) {
      this.page = +this.$route.query.page;
    }
    if (this.$route.query.perPage) {
      this.perPage = +this.$route.query.perPage;
    }
    await this.fetchAcitivities();
    this.loading = false;
  },
  methods: {
    ...mapActions({
      getActivities: "activities/getActivities",
      deleteActivity: "activities/deleteActivity",
    }),
    closeModal() {
      this.showModal = false;
      this.showActivity = false;
    },
    async doDelete(id) {
      const ok = await this.$refs.ConfirmDialogue.show({
        title: "Delete Page",
        message: this.hasAcces
          ? this.$t("confirm_delete_activity")
          : this.$t("access_delete"),
        okButton: this.$t("delete"),
        cancelButton: this.$t("cancel"),
      });
      if (ok) {
        await api({ requiresAuth: true }).delete(`/activity/remove/${id}`);
        let nextId = 1;
        const notification = {
          type: "success",
          message: this.$t("success_deleted_activity"),
          id: nextId++,
        };
        this.$store.commit("notification/ADD_NOTIFICATION", notification, {
          root: true,
        });
        await this.fetchAcitivities();
      }
    },
    pageNumberChange(page) {
      this.page = page;
    },
    pageSizeChange(perPage) {
      this.perPage = perPage;
    },
    async fetchAcitivities() {
      await this.getActivities({
        search: this.$route.query.search || this.search_activity,
        page: this.$route.query.page || this.page,
        perPage: this.$route.query.perPage || this.perPage,
      });
    },
  },
  computed: {
    ...mapGetters({
      activities: "activities/activities",
      totalActivities: "activities/totalActivities",
      showActivityModal: "modals/showActivityModal",
      addActivityModal: "modals/addActivityModal",
    }),

    tableColumns() {
      return [
        {
          field: "name",
          key: "a",
          title: this.$t("activities"),
          align: "left",
          width: "80%",
        },
        {
          field: "",
          key: "b",
          title: "",
          align: "left",
          width: "10%",
          renderBodyCell: ({ row }) => {
            return (
              <span>
                <button
                  class={this.hasAcces ? "btn btn--view" : "hideButton"}
                  on-click={() => {
                    this.activityItem = row;
                    this.$store.commit("modals/SET_SHOW_ACTIVITY_MODAL", true);
                  }}
                >
                  {this.$t("view")}
                </button>
              </span>
            );
          },
        },
        {
          field: "",
          key: "c",
          title: "",
          align: "right",
          width: "10%",
          renderBodyCell: ({ row }) => {
            return (
              <span>
                <button
                  class={this.hasAcces ? "btn btn--delete-trash" : "hideButton"}
                  on-click={() => {
                    this.doDelete(row.id);
                  }}
                >
                  {" "}
                  <font-awesome-icon icon="fa-solid fa-trash-can" />
                </button>
              </span>
            );
          },
        },
      ];
    },
  },
};
</script>

<style lang="scss">
@import "../assets/scss/components/global/page-global";
</style>
