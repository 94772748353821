<template>
  <div class="apptable">
    <div
      class="apptable__wrapper"
      id="loading-container"
      :class="getCurrentMode"
    >
      <ve-table
        :columns="columns"
        :row-style-option="rowStyleOption"
        :table-data="tableData"
      />
    </div>
    <div class="apptable__pagination">
      <ve-pagination
        :paging-count="1"
        :total="total"
        :page-index="parseInt($route.query.page) || page"
        :page-size="parseInt($route.query.perPage) || perPage"
        @on-page-number-change="changePage"
        @on-page-size-change="changeSizePage"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "AppInput",
  data() {
    return {
      loadingInstance: null,

      rowStyleOption: {
        stripe: true,
      },
    };
  },
  watch: {
    loading(val) {
      if (val) {
        this.show();
      } else {
        this.close();
      }
    },
    getCurrentMode(val) {
      const test = document.querySelectorAll(".ve-table-body-td");
      const test2 = document.querySelectorAll(".modal__wrapper");

      if (val == "dark") {
        for (const col of test) {
          col.classList.add("dark");
        }

        for (const col of test2) {
          col.classList.add("dark");
        }
      }
    },
  },

  methods: {
    async show() {
      this.loadingInstance.show();
    },
    close() {
      this.loadingInstance.close();
    },
  },
  async mounted() {
    this.loadingInstance = this.$veLoading({
      target: document.querySelector("#loading-container"),
      name: "wave",
    });
    await this.show();
  },
  computed: {
    ...mapGetters({
      getCurrentMode: "switcher/getCurrentMode",
    }),
  },
  props: {
    columns: {
      type: Array,
      required: true,
    },

    tableData: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
    },
    loadingContainer: {
      type: String,
      required: false,
    },
    total: {
      type: Number,
      default: 1,
    },
    page: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 10,
    },
    changePage: {
      type: Function,
      default: () => {},
    },
    changeSizePage: {
      type: Function,
      default: () => {},
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/components/global/app-table";
</style>
