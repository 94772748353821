import api from "@/api/api";

export default {
  namespaced: true,
  state: {
    childStat: [],
  },
  getters: {
    getChildStats: (state) => state.childStat,
  },
  mutations: {
    SET_CHILD_STATS(state, data) {
      state.childStat = data;
    },
  },
  actions: {
    async getChildrenStats({ commit }) {
      let data = await api({ requiresAuth: true }).get("/child/statistics");
      commit("SET_CHILD_STATS", data.data);
    },
  },
};
