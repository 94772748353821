<template>
  <div class="chart">
    <div class="chart__wrapper chart__wrapper--children">
      <div class="children-charts">
        <div class="chart__item">
          <h2 class="chart__item__title">{{ $t("children") }}</h2>
          <p class="chart__item__error" v-if="!getChildrenStats.allChildren">
            {{ $t("no_data") }}
          </p>
          <Doughnut
            v-else
            :chart-options="chartOptions"
            :chart-data="filteredChildren"
            :chart-id="chartId"
            :dataset-id-key="datasetIdKey"
            :plugins="plugins"
            :css-classes="cssClasses"
            :styles="styles"
            :width="width"
            :height="height"
          />
          <a
            class="chart__item__close"
            @click="$store.commit('modals/SET_SHOW_CHART_POPUP', false)"
          >
            <img src="../../assets/icons/close.svg" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Doughnut } from "vue-chartjs/legacy";
import { mapGetters } from "vuex";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from "chart.js";

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

export default {
  name: "ChildrenStats",
  components: {
    Doughnut,
  },

  props: {
    chartId: {
      type: String,
      default: "doughnut-chart",
    },
    chartIdClass: {
      type: String,
      default: "doughnut-chart-class",
    },

    datasetIdKey: {
      type: String,
      default: "label",
    },
    datasetIdKeyClass: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => ({}),
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },

      generalMsg: "",
    };
  },
  computed: {
    ...mapGetters({ getChildrenStats: "children/getChildrenStats" }),
    filteredChildren() {
      return {
        labels: this.getLabels,
        datasets: [
          {
            cutoutPercentage: 10,
            backgroundColor: ["#F05454", "#30475E", "#00B7C2"],
            data: this.getMappedStats,
          },
        ],
      };
    },
    getMappedStats() {
      return Object.values(this.getChildrenStats);
    },
    getLabels() {
      return Object.keys(this.getChildrenStats).map((key) =>
        key === "allChildren"
          ? "Total"
          : `${key[0].toUpperCase()}${key.slice(1)}`
      );
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/pages/_statistics.scss";
</style>
