<template>
  <div class="input">
    <label v-if="label" :for="id" class="input__label">{{ label }}</label>
    <input
      :type="type"
      :id="id"
      :placeholder="placeholder"
      :name="name"
      :value="value"
      @input="onInput($event)"
      class="input__input"
      @hasErrors="getErrorState($event)"
      :class="getCurrentMode"
    />
    <div
      @click="toggleShowPassword"
      v-if="password"
      class="input__icon input__icon--hide"
      :class="showPassword ? 'input__icon--show' : 'input__icon--hide'"
    ></div>
    <div
      v-if="show && !password"
      class="input__icon"
      :class="hasErrors ? '' : 'input__icon--correct'"
      v-bind="$attrs"
      v-on="$listeners"
    ></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  inheritsAttrs: false,
  name: "AppInput",
  data() {
    return {
      show: false,
    };
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    password: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number],
      default: "",
    },
    showPassword: {
      type: Boolean,
      default: false,
    },
    showCheckIcon: {
      type: Boolean,
      default: false,
    },
    hasErrors: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggleShowPassword() {
      this.$emit("password-toggle");
    },
    onInput({ target }) {
      this.$emit("input", target.value);
      if (target.value.length) {
        this.show = true;
      } else {
        this.show = false;
      }
    },
  },
  computed: {
    ...mapGetters({
      getCurrentMode: "switcher/getCurrentMode",
    }),
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/components/global/app-input";
</style>
