<template>
  <div class="modal" v-click-outside="onClickOutside">
    <div class="modal__wrapper">
      <h2 class="modal__title">{{ $t("child_details") }}</h2>
      <form @submit.prevent="update" class="modal__form">
        <div class="modal__form__input">
          <app-input
            :label="$t('edit_fullname')"
            :hasErrors="!!errors.fullName"
            :class="{ hasError: errors.fullName }"
            id="fullName"
            v-model="fullName"
            name="fullname"
            type="text"
            class="modal__form__input--type"
          />
          <p v-if="errors.fullName" class="error">{{ errors.fullName }}</p>
          <app-input
            :label="$t('edit_class')"
            :hasErrors="true"
            :class="{ hasError: errors.grade }"
            id="class"
            v-model="grade"
            name="class"
            type="number"
            class="modal__form__input--type"
          />
          <p v-if="errors.grade" class="error">{{ errors.grade }}</p>
          <app-input
            :label="$t('edit_age')"
            :hasErrors="true"
            :class="{ hasError: errors.age }"
            id="age"
            v-model="age"
            name="age"
            type="number"
            class="modal__form__input--type"
          />
          <p v-if="errors.age" class="error">{{ errors.age }}</p>
        </div>
        <p v-if="errors.msg" class="error">{{ errors.msg }}</p>
        <div class="modal__gender">
          <label for="male">
            <input type="radio" id="male" value="male" v-model="gender" />
            <span class="modal__gender--title">{{
              this.$t("male_select")
            }}</span>
          </label>
          <label for="female">
            <input type="radio" id="female" value="female" v-model="gender" />
            <span class="modal__gender--title">{{
              this.$t("female_select")
            }}</span>
          </label>
        </div>
        <div class="modal__btns">
          <app-button
            class="btn btn--cancel"
            type="button"
            @click.native="
              $store.commit('modals/SET_SHOW_CHILD_DETAILS_MODAL', false)
            "
            >{{ $t("cancel") }}
          </app-button>
          <app-button type="submit" class="btn btn--save">
            {{ $t("save") }}
          </app-button>
        </div>
      </form>
      <confirm-dialogue ref="ConfirmDialogue" />
      <a
        class="modal__close"
        @click="$store.commit('modals/SET_SHOW_CHILD_DETAILS_MODAL', false)"
        ><img src="../../assets/icons/close.svg"
      /></a>
    </div>
  </div>
</template>

<script>
import AppButton from "../global/AppButton.vue";
import ConfirmDialogue from "../global/ConfirmDialogue.vue";
import AppInput from "../global/AppInput.vue";
import { mapActions } from "vuex";
import api from "@/api/api";
import vClickOutside from "v-click-outside";

export default {
  components: { AppButton, ConfirmDialogue, AppInput },
  name: "ViewChild",
  data() {
    return {
      idUser: 0,
      fullName: null,
      age: null,
      grade: "",
      gender: null,
      errors: {
        grade: "",
        age: "",
        fullName: "",
        msg: "",
      },
    };
  },
  props: {
    childItem: {
      type: Object,
      required: true,
    },
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  watch: {},
  mounted() {
    this.idUser = this.childItem.id;
    this.fullName = this.childItem.full_name;
    this.age = this.childItem.age;
    this.grade = this.childItem.class;
    this.gender = this.childItem.gender;
  },

  methods: {
    onClickOutside() {
      this.$store.commit("modals/SET_SHOW_CHILD_DETAILS_MODAL", false);
    },
    ...mapActions({
      updateChild: "children/updateChild",
      updateChildWithApi: "children/updateChildWithApi",
      getAllChildren: "children/getAllChildren",
      showChildDetailsModal: "modals/showChildDetailsModal",
    }),

    async update() {
      this.errors.fullName = !this.fullName ? this.$t("empty_field") : "";
      this.errors.age = !this.age ? this.$t("empty_field") : "";
      this.errors.grade = !this.grade ? this.$t("empty_field") : "";

      if (this.fullName && this.age && this.grade && this.gender) {
        let dataToSend = {
          id: this.idUser,
          full_name: this.fullName,
          age: this.age,
          class: this.grade,
          gender: this.gender,
        };
        let currentData = this.childItem;

        let newDataToSend = {};
        for (const key in dataToSend) {
          if (Object.hasOwnProperty.call(dataToSend, key)) {
            const previousData = currentData[key];
            const currentChildData = dataToSend[key];
            if (previousData !== currentChildData) {
              newDataToSend = dataToSend;
            }
          }
        }

        if (!Object.keys(newDataToSend).length) {
          this.errors.msg = this.$t("same_child_data");
          return;
        }

        try {
          let data = await api({ requiresAuth: true }).put(
            `/child/${dataToSend.id}/update`,
            newDataToSend
          );
          this.$store.commit("children/UPDATE_CHILD", data.data);
          if (data.request.status) {
            let nextId = 1;
            const notification = {
              type: "success",
              message: this.$t("success_update_user"),
              id: nextId++,
            };
            this.$store.commit("notification/ADD_NOTIFICATION", notification, {
              root: true,
            });

            this.$store.commit("modals/SET_SHOW_CHILD_DETAILS_MODAL", false);
          }
        } catch (err) {
          console.error(err);
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/components/global/modal-global";
</style>
