<template>
  <div class="profile-dropdown" v-click-outside="onClickOutside">
    <nav class="profile-dropdown__nav">
      <ul
        @click="onClickOutside"
        class="profile-dropdown__nav__list"
        :class="getCurrentMode"
      >
        <router-link class="admin-router" :to="{ name: 'SuperAdminProfile' }">
          <li class="profile-dropdown__nav__list--item">
            <font-awesome-icon class="icons" icon="fa-regular fa-circle-user" />
            {{ $t("my_profile") }}
          </li>
        </router-link>
        <router-link
          class="admin-router"
          :class="
            this.$store.getters.isSuperAdmin ? 'admin-router' : 'hideButton'
          "
          :to="{ name: 'AdminsList' }"
        >
          <li class="profile-dropdown__nav__list--item">
            <font-awesome-icon class="icons" icon="fa-users" />
            <span>{{ $t("admins") }}</span>
          </li>
        </router-link>
        <router-link class="admin-router" to="/" @click.native="logout">
          <li class="profile-dropdown__nav__list--item">
            <font-awesome-icon
              class="icons"
              icon="fa-solid fa-arrow-right-from-bracket"
            />
            {{ $t("log_out") }}
          </li>
        </router-link>
      </ul>
    </nav>
  </div>
</template>

<script>
import { authComputed } from "@/store/modules/helpers";
import { mapGetters } from "vuex";
import vClickOutside from "v-click-outside";

export default {
  name: "showProfileDropdown",
  data() {
    return {
      hasAccess: true,
    };
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  computed: {
    ...authComputed,

    ...mapGetters({
      showProfileDropdown: "modals/showProfileDropdown",
      getCurrentMode: "switcher/getCurrentMode",
    }),
  },
  mounted() {
    this.hasAccess = this.$store.getters.isSuperAdmin;
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
    onClickOutside() {
      this.$store.commit("modals/SET_SHOW_PROFILE_DROPDOWN", false);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/pages/_profile-dropdown.scss";
</style>
