import api from "@/api/api";

export default {
  namespaced: true,
  state: {
    classesList: [],
    totalClasses: 0,
  },
  getters: {
    classesList: (state) => state.classesList,
    totalClasses: (state) => state.totalClasses,
  },
  mutations: {
    SET_ALL_CLASSES: (state, data) => {
      state.classesList = data;
    },
    SET_TOTAL_CLASSES: (state, data) => {
      state.totalClasses = data;
    },
    SET_CREATE_CLASS(state, data) {
      state.classesList.unshift(data);
    },
    DELETE_CLASS(state, index) {
      state.classesList.splice(index, 1);
    },
    UPDATE_CLASS(state, subjectData) {
      let findClass = state.classesList.find(
        (classes) => classes.id == subjectData.id
      );
      findClass.subject = subjectData.name;
    },
  },
  actions: {
    async getClasses({ commit }, { search, page, perPage }) {
      const params = {};
      // params.page = page ? page : "";
      // params.search = search ? search : "";
      // params.perPage = perPage ? perPage : "";

      if (search) {
        params.search = search;
      }

      if (page) {
        params.page = page;
      }
      if (perPage) {
        params.perPage = perPage;
      }
      try {
        const { data } = await api({ requiresAuth: true }).get(`/subject`, {
          params,
        });
        let dataToTable = data.Subjects.map((sub) => {
          return {
            id: sub.id,
            subject: sub.name,
            key: "b",
            title: "subject",
          };
        });
        commit("SET_ALL_CLASSES", dataToTable);
        commit("SET_TOTAL_CLASSES", data.total);
      } catch (err) {
        console.error(
          `Something went wrong.Error: ${err}. Couldn't get classes.`
        );
      }
    },
    async updateClass({ commit }, dataToSend) {
      commit("UPDATE_CLASS", dataToSend);
    },
    deleteClass(state, id) {
      try {
        api({ requiresAuth: true }).delete(`/remove/${id}`);
      } catch (err) {
        console.error("There was a problem deleting user " + err);
      }
    },
    async createClass({ commit }, data) {
      commit("SET_CREATE_CLASS", data);
    },
  },
};
