import Vue from "vue";
import Vuex from "vuex";
import auth from "@/store/modules/auth";
import modals from "@/store/modules/modals";
import activities from "@/store/modules/activities";
import classesList from "@/store/modules/classesList";
import users from "@/store/modules/users";
import notification from "@/store/modules/notification";
import superadmin from "@/store/modules/superadmin";
import { veLoading } from "vue-easytable";
import children from "@/store/modules/children";
import statistics from "@/store/modules/statistics";
import switcher from "@/store/modules/switcher";

Vue.prototype.$veLoading = veLoading;
import admins from "@/store/modules/admins";
Vue.use(Vuex);

Vue.use(Vuex);
Vue.prototype.$veLoading = veLoading;
export default new Vuex.Store({
  modules: {
    auth,
    modals,
    activities,
    classesList,
    users,
    notification,
    superadmin,
    admins,
    children,
    statistics,
    switcher,
  },
});
